import { ImagingActionTypes } from './constants';

interface ImagingState {
    loading: boolean;
    requirements: ImagingData[];
    modal: boolean;
    fileImg : {};
}

const INIT_STATE: ImagingState = {
    loading: false,
    requirements:[],
    modal : false,
    fileImg : {}
};

interface ImagingData {
    name: string;
    description : string;
    flagMedicalTeam:boolean;
    father : number;
}

interface ImagingActionsType {
    type:
        | ImagingActionTypes.API_RESPONSE_SUCCESS
        | ImagingActionTypes.API_RESPONSE_ERROR
        | ImagingActionTypes.CLOSE_FILE_UPLOAD
        | ImagingActionTypes.CLOSE_MODAL
        | ImagingActionTypes.OPEN_MODAL
        | ImagingActionTypes.LIST_REQUIREMENTS
        | ImagingActionTypes.UPLOAD_IMAGING;
    payload: {
        actionType?: string;
        data?: ImagingData | {};
        error?: string;
    };
}

interface State {
    requirement?: ImagingData | {};
    loading?: boolean;
    value?: boolean;
}

const RequirementImaging = (state: State = INIT_STATE, action: ImagingActionsType): any => {
   
    switch (action.type) {
        case ImagingActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ImagingActionTypes.LIST_REQUIREMENTS: {
                    return {
                        ...state,
                        requirements: action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case ImagingActionTypes.UPLOAD_IMAGING: {
                    return {
                        ...state,
                        fileImg: action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case ImagingActionTypes.OPEN_MODAL: {
                    return {
                        ...state,
                        modal: true,
                        loading: false,
                    };
                }
                case ImagingActionTypes.CLOSE_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal:false,
                        error:''
                    };
                }
                case ImagingActionTypes.CLOSE_FILE_UPLOAD:{
                    return {
                        ...state,
                        loading: false,
                        modal:false,
                        error:''
                    };
                }
                default:
                    return { ...state };
            }

        case ImagingActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ImagingActionTypes.LIST_REQUIREMENTS: {
                    return {
                        ...state,
                        area: action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case ImagingActionTypes.UPLOAD_IMAGING: {
                    return {
                        ...state,
                        area: action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case ImagingActionTypes.OPEN_MODAL: {
                    return {
                        ...state,
                        areas: action.payload.data,
                        loading: false,
                    };
                }
                case ImagingActionTypes.CLOSE_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal:true,
                        error:''
                    };
                }
                case ImagingActionTypes.CLOSE_FILE_UPLOAD:{
                    return {
                        ...state,
                        loading: false,
                        modal:false,
                        error:''
                    };
                }
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default RequirementImaging;
