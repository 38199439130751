// apicore
import { APICore } from '../../helpers/api/apiCore';

// constants
import { UserActionTypes } from './constants';

interface UserState {
    loading: boolean;
    users:UserData[];
    modal: boolean;
}
const INIT_STATE:UserState = {
    loading: false,
    users:[],
    modal: false
};

interface UserData {
    id?:number;
    email: string;
    password: string;
    name: string;
    surname: string;
    lastname: string;
    optionalEmail : string;
    localPhone : string;
    localExtension : string;
    directPhone: string;
    directExtension: string;
    cellPhone: string;
    optionalCellPhone : string;
    avatarLink: string;
    role: number;
    hospital: number;
    area: number;
    professionalLicense: string;
    degree: string
}

interface UserActionType {
    type:
        | UserActionTypes.API_RESPONSE_SUCCESS
        | UserActionTypes.API_RESPONSE_ERROR
        | UserActionTypes.UPDATE_USER
        | UserActionTypes.UPDATE_ADMIN_USER
        | UserActionTypes.SAVE_ADMIN_USER
        | UserActionTypes.GET_ADMIN_USER
        | UserActionTypes.GET_USERS
        | UserActionTypes.SAVE_USER
        | UserActionTypes.OPEN_MODAL
        | UserActionTypes.CLOSE_MODAL;

    payload: {
        actionType?: string;
        data?: UserData | {};
        error?: string;
    };
}

interface State {
    user?: UserData | {};
    loading?: boolean;
    value?: boolean;
    modal:boolean;
}

const User = (state: State = INIT_STATE, action: UserActionType): any => {
    switch (action.type) {
        case UserActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case UserActionTypes.SAVE_USER: {
                    return {
                        ...state,
                        user: action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case UserActionTypes.UPDATE_USER: {
                    return {
                        ...state,
                        user: action.payload.data,
                        modal: false,
                        loading: false,
                        error:''
                    };
                }
                case UserActionTypes.GET_USERS: {
                    
                    return {
                        ...state,
                        users: action.payload.data,
                        loading: false,
                    };
                }
                case UserActionTypes.SAVE_ADMIN_USER: {
                    return {
                        ...state,
                        user: action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case UserActionTypes.UPDATE_ADMIN_USER: {
                    return {
                        ...state,
                        user: action.payload.data,
                        modal: false,
                        loading: false,
                        error:''
                    };
                }
                case UserActionTypes.GET_ADMIN_USER: {
                    
                    return {
                        ...state,
                        users: action.payload.data,
                        loading: false,
                    };
                }
                case UserActionTypes.OPEN_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal: true,
                        error:''
                    };
                }
                case UserActionTypes.CLOSE_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal:false,
                    };
                }
                default:
                    return { ...state };
            }

        case UserActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case UserActionTypes.SAVE_USER: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        modal:false,
                    };
                }
                case UserActionTypes.UPDATE_USER: {
                    return {
                        ...state,
                        hospital: action.payload.data,
                        loading: false,
                    };
                }
                case UserActionTypes.GET_USERS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
              
                default:
                    return { ...state };
            }
          
        default:
            return { ...state };
    }
};

export default User;
