
import { APICore } from "./apiCore";


const api = new APICore();
const baseUrl = "/studies";

export const getStudies = async (id: number) => {
  return await api.get(`${baseUrl}/requirement/${id}`,'');
}


export const updateStatusStudy = async (params: {id: number; status: number}) => {
  return await api.update(`${baseUrl}/study/${params.id}/status/${params.status}/`,'');
}