
import { APICore } from "./apiCore";


const api = new APICore();
const baseUrl = "/enterprises";
export interface ResponseImage {
  url: string;
}
export const getAllEnterprises = async () => {
  return await api.get(`${baseUrl}/all/`,'');
}

export const deleteEnterprise = async (id: number) => {
  return await api.delete(`${baseUrl}/`+ id);
}

export const getFileEnterprise = async(name: string) =>{
  return api.getWithType<ResponseImage>(`${baseUrl}/files/${name}`, '');
}

export const saveEnterprise = async(params: { 
    name: string;
    file:  File | null;
}) =>{
  return await api.createWithFile(`${baseUrl}`,params);
}

export const updateEnterprise = async(params: { 
  id: number;
  name: string;
  file:  File | null;
}) =>{
  return await api.updateWithFile(`${baseUrl}`,params);
}
