// constants
import { AreasActionTypes } from "./constants";

export interface AreasActionsType {
  type:
    | AreasActionTypes.API_RESPONSE_SUCCESS
    | AreasActionTypes.API_RESPONSE_ERROR
    | AreasActionTypes.SAVE_AREA
    | AreasActionTypes.UPDATE_AREA
    | AreasActionTypes.GET_AREAS
       
  payload: {} | string;
}
interface AreaData {
  id: string;
  description : string;
  flagMedicalTeam:boolean;
}
// common success
export const areaApiResponseSuccess = (
  actionType: string,
  data: AreaData | {}
): AreasActionsType => ({
  type: AreasActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const areaApiResponseError = (
  actionType: string,
  error: string
): AreasActionsType => ({
  type: AreasActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});
export const saveArea = (
  description : string,
  flagMedicalTeam: boolean
): AreasActionsType => ({
  type: AreasActionTypes.SAVE_AREA,
  payload: {
    description,
    flagMedicalTeam
  },
});

export const getAreas = ()=>{
  return { type: AreasActionTypes.GET_AREAS }
}

export const deleteArea = (id:number)=>{
  return {type: AreasActionTypes.DELETE_AREA, payload:{id } }
}

export const updateArea = (
  id: number,
  description : string,
  flagMedicalTeam: boolean
): AreasActionsType => ({
  type: AreasActionTypes.UPDATE_AREA,
  payload: {
    id,
    description,
    flagMedicalTeam
  },
});

export const openModalArea = () =>{
  return { type: AreasActionTypes.OPEN_MODAL}
}
export const closeModalArea = () =>{
  return { type: AreasActionTypes.CLOSE_MODAL}
}