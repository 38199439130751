import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// helpers
import {
  saveEnterprise as saveEnterpriseApi,
  getAllEnterprises as getEnterprisesApi,
  getFileEnterprise as getFileEnterpriseApi,
  updateEnterprise as updateEnterpriseWithFileApi,
  deleteEnterprise as deleteEnterpriseApi
} from "../../helpers";

// actions
import { enterpriseApiResponseSuccess, enterpriseApiResponseError } from "./actions";

// constants
import { EnterprisesActionTypes } from "./constants";

interface EnterpriseData {
  payload: {
    id: number;
    name : string;
    file:  File | null;
  };
  type: string;
}

interface responsePath {
  url: string;
}

/**
 * Create Enterprise
 * @param {*} payload - 
 */
function* createEnterprise({
  payload : {
    name,
    file,
  } ,
  type,
} : EnterpriseData): SagaIterator {
  try {
    const response = yield call(saveEnterpriseApi,{
      name,
      file,
    });
    const data = response.data;
    yield put(enterpriseApiResponseSuccess(EnterprisesActionTypes.CREATE_ENTERPRISE, data));
  } catch (error: any) {
    yield put(enterpriseApiResponseError(EnterprisesActionTypes.CREATE_ENTERPRISE, error));
  }
}

export function* watchCreateEnterprise() {
  yield takeEvery(EnterprisesActionTypes.CREATE_ENTERPRISE, createEnterprise);
}

/**
 * Update Enterprise
 * @param {*} payload - 
 */
function* updateEnterprise({
  payload : {
    id,
    name,
    file,
  } ,
  type,
} : EnterpriseData): SagaIterator {
  try {
    const response = yield call(updateEnterpriseWithFileApi,{
      id,
      name,
      file,
    });
    const data = response.data;
    yield put(enterpriseApiResponseSuccess(EnterprisesActionTypes.UPDATE_ENTERPRISE, data));
  } catch (error: any) {
    yield put(enterpriseApiResponseError(EnterprisesActionTypes.UPDATE_ENTERPRISE, error));
  }
}

export function* watchUpdateEnterprise() {
  yield takeEvery(EnterprisesActionTypes.UPDATE_ENTERPRISE, updateEnterprise);
}






/**
 * Get Enterprises
 * @param {*} payload - 
 */
function* getEnterprises(): SagaIterator {
  try {
    const response = yield call(getEnterprisesApi);
    const data = response.data;
    yield put(enterpriseApiResponseSuccess(EnterprisesActionTypes.GET_ALL_ENTERPRISE, data));
  } catch (error: any) {
    yield put(enterpriseApiResponseError(EnterprisesActionTypes.GET_ALL_ENTERPRISE, error));
  }
}

export function* watchGetEnterprises() {
  yield takeEvery(EnterprisesActionTypes.GET_ALL_ENTERPRISE, getEnterprises);
}

/**
 * Get File
 * @param {*} payload - 
 */
function* getFileEnterprise({payload:{name},type}:EnterpriseData): SagaIterator {
  try {
    const response = yield call(getFileEnterpriseApi, name);
    let url: responsePath;
    url = response.data;
    var fileLink = document.createElement('a');
    fileLink.href = url.url;
    fileLink.setAttribute('download', 'nota.pdf');
    document.body.appendChild(fileLink);
    fileLink.click();
    yield put(enterpriseApiResponseSuccess(EnterprisesActionTypes.GET_FILE, ""));
  } catch (error: any) {
    yield put(enterpriseApiResponseError(EnterprisesActionTypes.GET_FILE, error));
  }
}

export function* watchGetFileEnterprises() {
  yield takeEvery(EnterprisesActionTypes.GET_FILE, getFileEnterprise);
}

function* openModalEnterprise({
  type,
}:any): SagaIterator{
    yield put(enterpriseApiResponseSuccess(EnterprisesActionTypes.OPEN_ENTERPRISE_MODAL,""));
 
}

export function* watchOpenModalEnterprise() {
  yield takeEvery(EnterprisesActionTypes.OPEN_ENTERPRISE_MODAL,openModalEnterprise);
}

function* closeModalEnterprise({
  type,
}:any): SagaIterator{
    yield put(enterpriseApiResponseSuccess(EnterprisesActionTypes.CLOSE_ENTERPRISE_MODAL,""));
 
}

export function* watchCloseModalEnterprise() {
  yield takeEvery(EnterprisesActionTypes.CLOSE_ENTERPRISE_MODAL,closeModalEnterprise);
}

/**
 * Delete
 * @param {*} payload - 
 */
function* deleteEnterprise({payload:{id},type}:EnterpriseData): SagaIterator {
  try {
    const response = yield call(deleteEnterpriseApi, id);
    const data:any = [];
    yield put(enterpriseApiResponseSuccess(EnterprisesActionTypes.DELETE_ENTERPRISE, data));
    yield call(getEnterprises);
  } catch (error: any) {
    yield put(enterpriseApiResponseError(EnterprisesActionTypes.DELETE_ENTERPRISE, error));
  }
}

export function* watchDeleteEnterprise() {
  yield takeEvery(EnterprisesActionTypes.DELETE_ENTERPRISE, deleteEnterprise);
}


function* enterpriseSaga() {
  yield all([
    fork(watchGetEnterprises),
    fork(watchCloseModalEnterprise),
    fork(watchOpenModalEnterprise),
    fork(watchCreateEnterprise),
    fork(watchGetFileEnterprises),
    fork(watchUpdateEnterprise),
    fork(watchDeleteEnterprise)
  ]);
}

export default enterpriseSaga;
