
import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// helpers
import {
  getSchedules as getScheduleApi,
  updateSchedule as updateScheduleApi
} from "../../helpers";

// actions
import { scheduleApiResponseSuccess, scheduleApiResponseError } from "./actions";

// constants
import { ScheduleActionTypes } from "./constants";

interface ScheduleData {
  payload: {
    id: number;
    weekNumber: number;
    userId: number;
    startTime: string;
    endTime: string;
    onVacation: boolean;
  };
  type: string;
}

/**
 * Update User
 * @param {*} payload - 
 */
function* updateScheduleS({
  payload: { 
    id,
    weekNumber,
    userId,
    startTime,
    endTime,
    onVacation
   },
  type,
}: ScheduleData): SagaIterator {
  try {
    const response = yield call(updateScheduleApi, {  
      id,
      weekNumber,
      userId,
      startTime,
      endTime,
      onVacation
    });
    const data = response.data;
   
    
    yield put(scheduleApiResponseSuccess(ScheduleActionTypes.UPDATE_SCHEDULE, data));
   
  } catch (error: any) {
    yield put(scheduleApiResponseError(ScheduleActionTypes.UPDATE_SCHEDULE, error));
  }
}

export function* watchUpdateSchedule() {
  yield takeEvery(ScheduleActionTypes.UPDATE_SCHEDULE, updateScheduleS);
}

/**
 * Get Users
 * @param {*} payload - 
 */
function* getSchedule({payload:{ id }, type}:ScheduleData): SagaIterator {
  try {

    const response = yield call(getScheduleApi,id);
    const data = response.data;
    yield put(scheduleApiResponseSuccess(ScheduleActionTypes.GET_SCHEDULES, data));
  } catch (error: any) {
    yield put(scheduleApiResponseError(ScheduleActionTypes.GET_SCHEDULES, error));
  }
}

export function* watchGetSchedule() {
  yield takeEvery(ScheduleActionTypes.GET_SCHEDULES, getSchedule);
}

function* openModalSchedule({
  type,
}:any): SagaIterator{
    yield put(scheduleApiResponseSuccess(ScheduleActionTypes.OPEN_MODAL,""));
 
}

export function* watchOpenModalSchedule() {
  yield takeEvery(ScheduleActionTypes.OPEN_MODAL,openModalSchedule);
}

function* closeModalSchedule({
  type,
}:any): SagaIterator{
    yield put(scheduleApiResponseSuccess(ScheduleActionTypes.CLOSE_MODAL,""));
 
}

export function* watchCloseModalSchedule() {
  yield takeEvery(ScheduleActionTypes.CLOSE_MODAL,closeModalSchedule);
}


function* scheduleSaga() {
  yield all([
    fork(watchCloseModalSchedule),
    fork(watchOpenModalSchedule),
    fork(watchUpdateSchedule),
    fork(watchGetSchedule),
  ]);
}

export default scheduleSaga;
