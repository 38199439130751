// constants

import { UserActionTypes } from "./constants";

export interface UserActionType {
  type:
    | UserActionTypes.API_RESPONSE_SUCCESS
    | UserActionTypes.API_RESPONSE_ERROR
    | UserActionTypes.SAVE_USER
    | UserActionTypes.UPDATE_USER
    | UserActionTypes.UPDATE_ADMIN_USER
    | UserActionTypes.SAVE_ADMIN_USER
    | UserActionTypes.GET_USERS
    | UserActionTypes.GET_ADMIN_USER
       
  payload: {} | string;
}
interface UserData {
  id?:number;
  email: string;
  password: string;
  name: string;
  surname: string;
  lastname: string;
  optionalEmail : string;
  localPhone : string;
  localExtension : number;
  directPhone: string;
  directExtension: number;
  cellPhone: string;
  optionalCellPhone : string;
  avatarLink: string;
  role: number;
  hospital: number;
  area: number;
  professionalLicense: string;
  degree: string;
}
// common success
export const userApiResponseSuccess = (
  actionType: string,
  data: UserData | {}
): UserActionType => ({
  type: UserActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const userApiResponseError = (
  actionType: string,
  error: string
): UserActionType => ({
  type: UserActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});
export const saveUser = (
  email: string,
  password: string,
  name: string,
  surname: string,
  lastname: string,
  optionalEmail : string,
  localPhone : string,
  localExtension : string,
  directPhone: string,
  directExtension: string,
  cellPhone: string,
  optionalCellPhone : string,
  avatarLink: string,
  role: number,
  hospital: number,
  area: number,
  adminPosition: string,
  adminArea: string,
  professionalLicense: string,
  degree: string,
): UserActionType => ({
  type: UserActionTypes.SAVE_USER,
  payload: {
  email,
  password,
  name,
  surname,
  lastname,
  optionalEmail,
  localPhone,
  localExtension,
  directPhone,
  directExtension,
  cellPhone,
  optionalCellPhone,
  avatarLink,
  role,
  hospital,
  area,
  adminPosition,
  adminArea,
  professionalLicense,
  degree
  },
});

export const saveAdminUser = (
  email: string,
  password: string,
  name: string,
  surname: string,
  lastname: string,
  optionalEmail : string,
  localPhone : string,
  localExtension : string,
  directPhone: string,
  directExtension: string,
  cellPhone: string,
  optionalCellPhone : string,
  avatarLink: string,
  role: number,
  hospital: number,
  area: number,
  adminPosition: string,
  adminArea: string,
  professionalLicense: string,
  degree: string,
): UserActionType => ({
  type: UserActionTypes.SAVE_ADMIN_USER,
  payload: {
  email,
  password,
  name,
  surname,
  lastname,
  optionalEmail,
  localPhone,
  localExtension,
  directPhone,
  directExtension,
  cellPhone,
  optionalCellPhone,
  avatarLink,
  role,
  hospital,
  area,
  adminPosition,
  adminArea,
  professionalLicense,
  degree
  },
});

export const getUsers = ()=>{
  return { type: UserActionTypes.GET_USERS }
}

export const getAdminUsers = ()=>{
  return { type: UserActionTypes.GET_ADMIN_USER }
}

export const updateUser = (
  id: number,
  email: string,
  password: string,
  name: string,
  surname: string,
  lastname: string,
  optionalEmail : string,
  localPhone : string,
  localExtension : string,
  directPhone: string,
  directExtension: string,
  cellPhone: string,
  optionalCellPhone : string,
  avatarLink: string,
  role: number,
  hospital: number,
  area: number,
  adminPosition: string,
  adminArea: string,
  professionalLicense: string,
  degree: string
): UserActionType => ({
  type: UserActionTypes.UPDATE_USER,
  payload: {
    id,
    email,
    password,
    name,
    surname,
    lastname,
    optionalEmail,
    localPhone,
    localExtension,
    directPhone,
    directExtension,
    cellPhone,
    optionalCellPhone,
    avatarLink,
    role,
    hospital,
    area,
    adminPosition,
    adminArea,
    professionalLicense,
    degree
  },
});
export const updateAdminUser = (
  id: number,
  email: string,
  password: string,
  name: string,
  surname: string,
  lastname: string,
  optionalEmail : string,
  localPhone : string,
  localExtension : string,
  directPhone: string,
  directExtension: string,
  cellPhone: string,
  optionalCellPhone : string,
  avatarLink: string,
  role: number,
  hospital: number,
  area: number,
  adminPosition: string,
  adminArea: string,
  professionalLicense: string,
  degree: string
): UserActionType => ({
  type: UserActionTypes.UPDATE_ADMIN_USER,
  payload: {
    id,
    email,
    password,
    name,
    surname,
    lastname,
    optionalEmail,
    localPhone,
    localExtension,
    directPhone,
    directExtension,
    cellPhone,
    optionalCellPhone,
    avatarLink,
    role,
    hospital,
    area,
    adminPosition,
    adminArea,
    professionalLicense,
    degree
  },
});
export const deleteUser = (id : number)=>{
  return { type: UserActionTypes.DELETE_USER,payload: id }
}

export const openModalUser = () =>{
  return { type: UserActionTypes.OPEN_MODAL}
}
export const closeModalUser = () =>{
  return { type: UserActionTypes.CLOSE_MODAL}
}