

// constants
import { DirectoryActionTypes } from './constants';

interface DirectoryState {
    loading: boolean;
    directories:DirectoryData[];
    modal: boolean;
}

const INIT_STATE:DirectoryState = {
    loading: false,
    directories:[],
    modal : false,
};

interface DirectoryData {
    id:number;
    email:string;
    name: string;
    surname: string;
    lastname: string;
    optionalEmail: string;
    localPhone: string;
    localExtension: string;
    directPhone: string;
    cellPhone: string;
    hospital:number;
}

interface DirectoryActionType {
    type:
        | DirectoryActionTypes.API_RESPONSE_SUCCESS
        | DirectoryActionTypes.API_RESPONSE_ERROR
        | DirectoryActionTypes.SAVE_DIRECTORY
        | DirectoryActionTypes.UPDATE_DIRECTORY
        | DirectoryActionTypes.GET_DIRECTORIES
        | DirectoryActionTypes.DELETE_DIRECTORY
        | DirectoryActionTypes.OPEN_MODAL
        | DirectoryActionTypes.CLOSE_MODAL;
    payload: {
        actionType?: string;
        data?: DirectoryData | {};
        error?: string;
    };
}

interface State {
    directory?: DirectoryData | {};
    loading?: boolean;
    value?: boolean;
    modal:boolean;
}

const Directory = (state: State = INIT_STATE, action: DirectoryActionType): any => {
    switch (action.type) {
        case DirectoryActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case DirectoryActionTypes.SAVE_DIRECTORY: {
                    return {
                        ...state,
                        directory: action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case DirectoryActionTypes.UPDATE_DIRECTORY: {
                    return {
                        ...state,
                        directory: action.payload.data,
                        modal: false,
                        loading: false,
                        error:''
                    };
                }
                case DirectoryActionTypes.GET_DIRECTORIES: {
                    return {
                        ...state,
                        directories: action.payload.data,
                        loading: false,
                    };
                }
                case DirectoryActionTypes.OPEN_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal:true,
                        error:''
                    };
                }
                case DirectoryActionTypes.CLOSE_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal:false,
                    };
                }
                default:
                    return { ...state };
            }

        case DirectoryActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case DirectoryActionTypes.SAVE_DIRECTORY: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case DirectoryActionTypes.UPDATE_DIRECTORY: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }


                case DirectoryActionTypes.GET_DIRECTORIES: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
              
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default Directory;
