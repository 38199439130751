// constants
import { DirectoryActionTypes } from "./constants";

export interface DirectoryActionType {
  type:
    | DirectoryActionTypes.API_RESPONSE_SUCCESS
    | DirectoryActionTypes.API_RESPONSE_ERROR
    | DirectoryActionTypes.SAVE_DIRECTORY
    | DirectoryActionTypes.UPDATE_DIRECTORY
    | DirectoryActionTypes.GET_DIRECTORIES
    | DirectoryActionTypes.DELETE_DIRECTORY
       
  payload: {} | string;
}
export interface Hospital {
  id:number;
  name: string;
  rfc: string;
  businessName: string;
  addressStreet: string;
  addressSuburb: string;
  addressMayoralty : string;
  addressPostalCode : string;
  addressState : string;
}
interface DirectoryData {
  id:number;
  email:string;
  name: string;
  surname: string;
  lastname: string;
  position: string;
  optionalEmail: string;
  localPhone: string;
  localExtension: string;
  directPhone: string;
  cellPhone: string;
  avatarLink:string;
  hospital:number;
}
// common success
export const directoryApiResponseSuccess = (
  actionType: string,
  data: DirectoryData | {}
): DirectoryActionType => ({
  type: DirectoryActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const directoryApiResponseError = (
  actionType: string,
  error: string
): DirectoryActionType => ({
  type: DirectoryActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});
export const saveDirectory = (
  name: string,
  email:string,
  surname: string,
  lastname: string,
  position: string,
  optionalEmail: string,
  localPhone: string,
  localExtension: number,
  directPhone: string,
  cellPhone: string,
  avatarLink:string,
  hospital:number,
): DirectoryActionType => ({
  type: DirectoryActionTypes.SAVE_DIRECTORY,
  payload: {
  name,
  email,
  surname,
  lastname,
  position,
  optionalEmail,
  localPhone,
  localExtension,
  directPhone ,
  cellPhone,
  avatarLink,
  hospital
  },
});
export const updateDirectory = (
  id:number,
  name: string,
  email:string,
  surname: string,
  lastname: string,
  position: string,
  optionalEmail: string,
  localPhone: string,
  localExtension: number,
  directPhone: string,
  cellPhone: string,
  avatarLink:string,
  hospital:number,
): DirectoryActionType => ({
  type: DirectoryActionTypes.UPDATE_DIRECTORY,
  payload: {
  id,
  name,
  email,
  surname,
  lastname,
  position,
  optionalEmail,
  localPhone,
  localExtension,
  directPhone ,
  cellPhone,
  avatarLink,
  hospital
  },
});

export const getDirectories = ()=>{
  return { type: DirectoryActionTypes.GET_DIRECTORIES }
}

export const deleteDirectory = (id : number)=>{
  return { type: DirectoryActionTypes.DELETE_DIRECTORY,payload: id }
}
export const openModalDirectory = () =>{
  return { type: DirectoryActionTypes.OPEN_MODAL}
}
export const closeModalDirectory = () =>{
  return { type: DirectoryActionTypes.CLOSE_MODAL}
}