import { FilesActionTypes } from "./constants";

export interface FilesActionsType {
  type:
    | FilesActionTypes.API_RESPONSE_SUCCESS
    | FilesActionTypes.API_RESPONSE_ERROR
    | FilesActionTypes.GET_FILE_BY_ID
    | FilesActionTypes.DELETE_FILE
    | FilesActionTypes.GET_FILES
    | FilesActionTypes.GET_FILE_BY_NAME
  payload: {} | string;
}

interface FilesData {
  id: number;
  protocol : string;
  patient: string;
}



// common success
export const filesApiResponseSuccess = (
  actionType: string,
  data: FilesData | {}
): FilesActionsType => ({
  type: FilesActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const filesApiResponseError = (
  actionType: string,
  error: string
): FilesActionsType => ({
  type: FilesActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});



export const getAllScales = (id : number)=>{
  return { type: FilesActionTypes.GET_FILES ,payload:{id}}
} 

export const getFileScale = (name : string)=>{
  return { type: FilesActionTypes.GET_FILE_BY_NAME ,payload:{name}}
} 

