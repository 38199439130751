

import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// helpers
import {
  saveMedicalNote as saveMedicalNoteApi,
  getAllMedicalNotes as getAllMedicalNotesApi,
  getPatientsWithoutNote as getPatientsWithoutNoteApi,
  downloadNote as downloadNoteApi,
  updateMedicalNote as updateMedicalNoteApi
} from "../../helpers";

// actions
import { medicalNoteApiResponseSuccess, medicalNoteApiResponseError} from "./actions";

// constants
import { MedicalNotesActionTypes } from "./constants";

interface MedicalNoteData {
  payload: {
    id?:number;
    treatingDoctor: number;
    description: string;
    path: string;
    patient: number;
    treatment: number;
  };
  type: string;
}

interface responsePath {
  url: string;
}

/**
 * Create Hospital
 * @param {*} payload - 
 */
function* createMedicalNote({
  payload: { treatingDoctor, description,  patient, treatment },
  type,
}: MedicalNoteData): SagaIterator {
  try {
    const response = yield call(saveMedicalNoteApi, {  
      treatingDoctor,
      description,
      patient,
      treatment,
    });
    const data = response.data;
    const medicalNote = data.note;
    
    yield put(medicalNoteApiResponseSuccess(MedicalNotesActionTypes.SAVE_NOTE, medicalNote));
    yield call(getMedicalNotes);

  } catch (error: any) {
    yield put(medicalNoteApiResponseError(MedicalNotesActionTypes.SAVE_NOTE, error));
  }
}



export function* watchCreateMedicalNote() {
  yield takeEvery(MedicalNotesActionTypes.SAVE_NOTE, createMedicalNote);
}


function* updateMedicalNote({
  payload: {id, treatingDoctor, description,  patient, treatment },
  type,
}: MedicalNoteData): SagaIterator {
  try {
    const response = yield call(updateMedicalNoteApi, {  
      id,
      treatingDoctor,
      description,
      patient,
      treatment,
    });
    const data = response.data;
    const medicalNote = data.note;
    
    yield put(medicalNoteApiResponseSuccess(MedicalNotesActionTypes.UPDATE_NOTE, medicalNote));
    yield call(getMedicalNotes);

  } catch (error: any) {
    yield put(medicalNoteApiResponseError(MedicalNotesActionTypes.UPDATE_NOTE, error));
  }
}



export function* watchUpdateMedicalNote() {
  yield takeEvery(MedicalNotesActionTypes.UPDATE_NOTE, updateMedicalNote);
}



/**
 * Get Medical Notes
 * @param {*} payload - 
 */
function* getMedicalNotes(): SagaIterator {
  try {
    const response = yield call(getAllMedicalNotesApi, );
    const data = response.data;
    yield put(medicalNoteApiResponseSuccess(MedicalNotesActionTypes.GET_NOTES, data));
  } catch (error: any) {
    yield put(medicalNoteApiResponseError(MedicalNotesActionTypes.GET_NOTES, error));
  }
}

export function* watchGetMedicalNotes() {
  yield takeEvery(MedicalNotesActionTypes.GET_NOTES, getMedicalNotes);
}

/**
 * Get Medical Notes
 * @param {*} payload - 
 */
function* getPatients(): SagaIterator {
  try {
    const response = yield call(getPatientsWithoutNoteApi, );
    const data = response.data;
    yield put(medicalNoteApiResponseSuccess(MedicalNotesActionTypes.GET_PATIENTS, data));
  } catch (error: any) {
    yield put(medicalNoteApiResponseError(MedicalNotesActionTypes.GET_PATIENTS, error));
  }
}

export function* watchGetPatients() {
  yield takeEvery(MedicalNotesActionTypes.GET_PATIENTS, getPatients);
}


function* openNoteModal({
  type,
}:any): SagaIterator{
    yield put(medicalNoteApiResponseSuccess(MedicalNotesActionTypes.OPEN_NOTE_MODAL,""));
 
}

export function* watchOpenNoteModal() {
  yield takeEvery(MedicalNotesActionTypes.OPEN_NOTE_MODAL,openNoteModal);
}

function* closeNoteModal({
  type,
}:any): SagaIterator{
    yield put(medicalNoteApiResponseSuccess(MedicalNotesActionTypes.CLOSE_NOTE_MODAL,""));
 
}

export function* watchCloseNoteModal() {
  yield takeEvery(MedicalNotesActionTypes.CLOSE_NOTE_MODAL,closeNoteModal);
}

/**
 * Get File
 * @param {*} payload - 
 */
function* getNoteFile({payload:{path},type}:MedicalNoteData): SagaIterator {
  try {
    const response = yield call(downloadNoteApi, path);
    let url: responsePath;
    url = response.data;
    var fileLink = document.createElement('a');
    fileLink.href = url.url;
    fileLink.setAttribute('download', path);
    document.body.appendChild(fileLink);
    fileLink.click();
    yield put(medicalNoteApiResponseSuccess(MedicalNotesActionTypes.DOWNLOAD_NOTE, ""));
  } catch (error: any) {
    yield put(medicalNoteApiResponseError(MedicalNotesActionTypes.DOWNLOAD_NOTE, error));
  }
}

export function* watchGetNoteFile() {
  yield takeEvery(MedicalNotesActionTypes.DOWNLOAD_NOTE, getNoteFile);
}




function* medicalNoteSaga() {
  yield all([
    fork(watchCreateMedicalNote),
    fork(watchGetMedicalNotes),
    fork(watchOpenNoteModal),
    fork(watchCloseNoteModal),
    fork(watchGetPatients),
    fork(watchUpdateMedicalNote),
    fork(watchGetNoteFile)
  ]);
}

export default medicalNoteSaga;
