// constants
import { AreasActionTypes } from './constants';

interface AreaState {
    loading: boolean;
    areas: AreaData[];
    modal: boolean;
}

const INIT_STATE: AreaState = {
    loading: false,
    areas:[],
    modal : false,
};

interface AreaData {
    name: string;
    description : string;
    flagMedicalTeam:boolean;
    father : number;
}

interface AreasActionsType {
    type:
        | AreasActionTypes.API_RESPONSE_SUCCESS
        | AreasActionTypes.API_RESPONSE_ERROR
        | AreasActionTypes.UPDATE_AREA
        | AreasActionTypes.GET_AREAS
        | AreasActionTypes.DELETE_AREA
        | AreasActionTypes.SAVE_AREA;
    payload: {
        actionType?: string;
        data?: AreaData | {};
        error?: string;
    };
}

interface State {
    area?: AreaData | {};
    loading?: boolean;
    value?: boolean;
}

const Area = (state: State = INIT_STATE, action: AreasActionsType): any => {
   
    switch (action.type) {
        case AreasActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case AreasActionTypes.SAVE_AREA: {
                    return {
                        ...state,
                        area: action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case AreasActionTypes.UPDATE_AREA: {
                    return {
                        ...state,
                        area: action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case AreasActionTypes.GET_AREAS: {
                    return {
                        ...state,
                        areas: action.payload.data,
                        loading: false,
                    };
                }
                case AreasActionTypes.DELETE_AREA: {
                    return {
                        ...state,
                        areas: action.payload.data,
                        loading: false,
                    };
                }
                case AreasActionTypes.OPEN_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal:true,
                        error:''
                    };
                }
                case AreasActionTypes.CLOSE_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal:false,
                        error:''
                    };
                }
                default:
                    return { ...state };
            }

        case AreasActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case AreasActionTypes.SAVE_AREA: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case AreasActionTypes.UPDATE_AREA: {
                    return {
                        ...state,
                        hospital: action.payload.data,
                        loading: false,
                    };
                }
                case AreasActionTypes.GET_AREAS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
              
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default Area;