
import { Patient } from "../../pages/medic-notes/types";
import { APICore } from "./apiCore";


const api = new APICore();
const baseUrl = "/patients";


export const getPatientsById= async (id: string) => {
    return await api.getWithType<Patient>(`${baseUrl}/${id}`,'');
}
export const getAllPatients= async () => {
  return await api.get(`${baseUrl}/all`,'');
}

  
  export const updateDischargedPatient= async (id: number) => {
    return api.get(`${baseUrl}/discharged/${id}`, '')
  }