export enum EnterprisesActionTypes {
    API_RESPONSE_SUCCESS = '@@enterprises/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@enterprises/API_RESPONSE_ERROR',
    GET_ALL_ENTERPRISE = '@@enterprises/GET_ALL_ENTERPRISE',
    GET_FILE = '@@enterprises/GET_FILE',
    CREATE_ENTERPRISE = '@@enterprises/CREATE_ENTERPRISE',
    DELETE_ENTERPRISE = '@@enterprises/DELETE_ENTERPRISE',
    UPDATE_ENTERPRISE = '@@enterprises/UPDATE_ENTERPRISE',
    OPEN_ENTERPRISE_MODAL = '@@enterprises/OPEN_ENTERPRISE_MODAL',
    CLOSE_ENTERPRISE_MODAL = '@@enterprises/CLOSE_ENTERPRISE_MODAL',
}
