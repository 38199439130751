import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// helpers
import {
  saveArea as saveAreaApi,
  updateArea as updateAreaApi,
  getAreas as getAreasApi,
  deleteArea as deleteAreaApi
} from "../../helpers";

// actions
import { areaApiResponseSuccess, areaApiResponseError } from "./actions";

// constants
import { AreasActionTypes } from "./constants";

interface AreaData {
  payload: {
    id:number;
    description : string;
    flagMedicalTeam:boolean;
  };
  type: string;
}

/**
 * Create Area
 * @param {*} payload - 
 */
function* createArea({
  payload: {  
    description ,
    flagMedicalTeam,
  },
  type,
}: AreaData): SagaIterator {
  try {
    const response = yield call(saveAreaApi, {  
      description ,
      flagMedicalTeam,
    });
    const data = response.data;
    const area = data.area;
    
    yield put(areaApiResponseSuccess(AreasActionTypes.SAVE_AREA, area));
    yield call(getAreas);
  } catch (error: any) {
    yield put(areaApiResponseError(AreasActionTypes.SAVE_AREA, error));
  }
}



export function* watchCreateArea() {
  yield takeEvery(AreasActionTypes.SAVE_AREA, createArea);
}


/**
 * Update Area
 * @param {*} payload - 
 */
function* updateArea({
  payload: { 
    id, 
    description ,
    flagMedicalTeam,
  },
  type,
}: AreaData): SagaIterator {
  try {
    const response = yield call(updateAreaApi, {  
      id, 
      description ,
      flagMedicalTeam,
    });
    const data = response.data;
    const area = data.area;
    
    yield put(areaApiResponseSuccess(AreasActionTypes.UPDATE_AREA, area));
    yield call(getAreas);
  } catch (error: any) {
    yield put(areaApiResponseError(AreasActionTypes.UPDATE_AREA, error));
  }
}



export function* watchUpdateArea() {
  yield takeEvery(AreasActionTypes.UPDATE_AREA, updateArea);
}

/**
 * Get  Areas
 * @param {*} payload - 
 */
function* getAreas(): SagaIterator {
  try {
    const response = yield call(getAreasApi, );
    const data = response.data;
    yield put(areaApiResponseSuccess(AreasActionTypes.GET_AREAS, data));
  } catch (error: any) {
    yield put(areaApiResponseError(AreasActionTypes.GET_AREAS, error));
  }
}



export function* watchGetAreas() {
  yield takeEvery(AreasActionTypes.GET_AREAS, getAreas);
}

/**
 * Delete
 * @param {*} payload - 
 */
function* deleteArea({payload:{id},type}:AreaData): SagaIterator {
  try {
    const response = yield call(deleteAreaApi, id);
    const data:any = [];
    yield put(areaApiResponseSuccess(AreasActionTypes.DELETE_AREA, data));
    yield call(getAreas);
  } catch (error: any) {
    yield put(areaApiResponseError(AreasActionTypes.DELETE_AREA, error));
  }
}

export function* watchDeleteArea() {
  yield takeEvery(AreasActionTypes.DELETE_AREA, deleteArea);
}

function* openModalArea({
  type,
}:any): SagaIterator{
    yield put(areaApiResponseSuccess(AreasActionTypes.OPEN_MODAL,""));
 
}

export function* watchOpenModalArea() {
  yield takeEvery(AreasActionTypes.OPEN_MODAL,openModalArea);
}

function* closeModalArea({
  type,
}:any): SagaIterator{
    yield put(areaApiResponseSuccess(AreasActionTypes.CLOSE_MODAL,""));
 
}

export function* watchCloseModalArea() {
  yield takeEvery(AreasActionTypes.CLOSE_MODAL,closeModalArea);
}


function* areaSaga() {
  yield all([
    fork(watchCloseModalArea),
    fork(watchOpenModalArea),
    fork(watchCreateArea),
    fork(watchUpdateArea),
    fork(watchGetAreas),
    fork(watchDeleteArea)
  ]);
}

export default areaSaga;