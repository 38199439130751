
import { APICore } from "./apiCore";


const api = new APICore();
const baseUrl = "/providers-visits";

export const getVisitsByProvider = async (id: number) => {
  return await api.get(`${baseUrl}/${id}`,'');
}

export const getAllVisitsProvider = async () => {
  return await api.get(`${baseUrl}/all`,'');
}


