import { EquipmentsActionTypes } from "./constants";

export interface EquipmentsActionsType {
  type:
    | EquipmentsActionTypes.API_RESPONSE_SUCCESS
    | EquipmentsActionTypes.API_RESPONSE_ERROR
    | EquipmentsActionTypes.CREATE_EQUIPMENT
    | EquipmentsActionTypes.GET_EQUIPMENT_BY_ID
    | EquipmentsActionTypes.GET_FILE
    | EquipmentsActionTypes.OPEN_EQUIPMENT_MODAL
    | EquipmentsActionTypes.CLOSE_EQUIPMENT_MODAL
    | EquipmentsActionTypes.UPDATE_EQUIPMENT
    | EquipmentsActionTypes.DELETE_EQUIPMENT
       
  payload: {} | string;
}

interface EquipmentData {
  orderNumber: string;
  brand :  string;
  model:  string;
  quantity : number;
  characteristic : string;
  price : number;
  total :number;
  file:  File | null;
}



// common success
export const equipmentApiResponseSuccess = (
  actionType: string,
  data: EquipmentData | {}
): EquipmentsActionsType => ({
  type: EquipmentsActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const equipmentApiResponseError = (
  actionType: string,
  error: string
): EquipmentsActionsType => ({
  type: EquipmentsActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});



export const saveEquipmentWithFile = (
  orderNumber: number,
  brand :  string,
  model:  string,
  quantity : number,
  characteristic : string,
  price : number,
  total :number,
): EquipmentsActionsType => ({
    type: EquipmentsActionTypes.CREATE_EQUIPMENT,
    payload: {
      orderNumber,
      brand,
      model,
      quantity,
      characteristic,
      price,
      total,
    },
  });

  export const updateEquipment = (
    id: number,
    orderNumber: number,
    brand :  string,
    model:  string,
    quantity : number,
    characteristic : string,
    price : number,
    total :number,
  ): EquipmentsActionsType => ({
      type: EquipmentsActionTypes.UPDATE_EQUIPMENT,
      payload: {
        id,
        orderNumber,
        brand,
        model,
        quantity,
        characteristic,
        price,
        total,
      },
    });  

export const deleteEquipment = (id : number)=>{
      return { type: EquipmentsActionTypes.DELETE_EQUIPMENT,payload:{id} }
     } 
export const getEquipments = (id : number)=>{
    return { type: EquipmentsActionTypes.GET_EQUIPMENT_BY_ID,payload:{id} }
}  

export const getFileEquipments = (name : string)=>{
  return { type: EquipmentsActionTypes.GET_FILE ,payload:{name}}
} 

export const openModalEquipment = () =>{
  return { type: EquipmentsActionTypes.OPEN_EQUIPMENT_MODAL}
}
export const closeModalEquipment = () =>{
  return { type: EquipmentsActionTypes.CLOSE_EQUIPMENT_MODAL}
}