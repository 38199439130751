
import { APICore } from "./apiCore";


const api = new APICore();
const baseUrl = "/requirement-files";


export const getFilesStudies = async (id: number) => {
  return await api.get(`${baseUrl}/study/${id}`,'');
}

export const uploadFileImaging= async(params: { 
  study: number;
  file:  File | null | undefined;
}) =>{
  return api.createWithFile(`${baseUrl}/image/`, params);
}

export const uploadFileLaboratory= async(params: { 
  study: number;
  file:  File | null | undefined;
}) =>{
  return api.createWithFile(`${baseUrl}/lab/`, params);
}

export const deleteFileImage = async (params:{id: number; name: string}) => {
  return await api.delete(`${baseUrl}/files/image/${params.id}/${params.name}`);
}

export const deleteFileLab = async (params:{id: number; name: string}) => {
  return await api.delete(`${baseUrl}/files/lab/${params.id}/${params.name}`);
}

export const getFileImg = async(name: string) =>{
  return api.get(`${baseUrl}/files/image/${name}`, '');
}

export const getFileLab = async(name: string) =>{
  return api.get(`${baseUrl}/files/lab/${name}`, '');
}
