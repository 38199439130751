
import { MedicalNotesActionTypes } from "./constants";

export interface MedicalNotesActionType {
  type:
    | MedicalNotesActionTypes.API_RESPONSE_SUCCESS
    | MedicalNotesActionTypes.API_RESPONSE_ERROR
    | MedicalNotesActionTypes.CREATE_NOTE
    | MedicalNotesActionTypes.SAVE_NOTE
    | MedicalNotesActionTypes.DOWNLOAD_NOTE
    | MedicalNotesActionTypes.UPDATE_NOTE
    | MedicalNotesActionTypes.GET_NOTES
    | MedicalNotesActionTypes.DELETE_NOTE
    | MedicalNotesActionTypes.OPEN_NOTE_MODAL
    | MedicalNotesActionTypes.CLOSE_NOTE_MODAL
  payload: {} | string;
}
interface MedicalNoteData {
  treatingDoctor: number;
  description: string;
  patient: number;
  treatment: number;
}
// common success
export const medicalNoteApiResponseSuccess = (
  actionType: string,
  data: MedicalNoteData | {}
): MedicalNotesActionType => ({
  type: MedicalNotesActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const medicalNoteApiResponseError = (
  actionType: string,
  error: string
): MedicalNotesActionType => ({
  type: MedicalNotesActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});
export const saveMedicalNote = (
  treatingDoctor: number,
  description: string,
  patient: number,
  treatment: number,
): MedicalNotesActionType => ({
  type: MedicalNotesActionTypes.SAVE_NOTE,
  payload: {
    treatingDoctor,
    description,
    patient,
    treatment,
  },
});

export const getMedicalNotes = ()=>{
  return { type: MedicalNotesActionTypes.GET_NOTES }
}

export const getPatientsWithout = ()=>{
  return { type: MedicalNotesActionTypes.GET_PATIENTS }
}

export const downloadNote = (path: string)=>{
  return { type: MedicalNotesActionTypes.DOWNLOAD_NOTE ,payload:{path}}
}

export const openModalNote = () =>{
  return { type: MedicalNotesActionTypes.OPEN_NOTE_MODAL}
}
export const closeModalNote = () =>{
  return { type: MedicalNotesActionTypes.CLOSE_NOTE_MODAL}
}