// constants
import { ScheduleActionTypes } from './constants';

interface ScheduleState {
    loading: boolean;
    schedules: ScheduleData[];
    modal: boolean;
}
const INIT_STATE: ScheduleState = {
    loading : false,
    schedules : [],
    modal : false
};

interface ScheduleData {
    id: number;
    weekNumber: number;
    userId: number;
    startTime: string;
    endTime: string;
    onVacation: boolean;
}

interface ScheduleActionType {
    type:
        | ScheduleActionTypes.API_RESPONSE_SUCCESS
        | ScheduleActionTypes.API_RESPONSE_ERROR
        | ScheduleActionTypes.UPDATE_SCHEDULE
        | ScheduleActionTypes.GET_SCHEDULES
        | ScheduleActionTypes.OPEN_MODAL
        | ScheduleActionTypes.CLOSE_MODAL;

    payload: {
        actionType?: string;
        data?: ScheduleData | {};
        error?: string;
    };
}

interface State {
    schedule?: ScheduleData | {};
    loading?: boolean;
    value?: boolean;
    modal:boolean;
}

const User = (state: State = INIT_STATE, action: ScheduleActionType): any => {
   
    switch (action.type) {
        case ScheduleActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
              
                case ScheduleActionTypes.UPDATE_SCHEDULE: {
                    return {
                        ...state,
                        schedule: action.payload.data,
                        modal: false,
                        loading: false,
                        error:''
                    };
                }
                case ScheduleActionTypes.GET_SCHEDULES: {
                    
                    return {
                        ...state,
                        schedules: action.payload.data,
                        loading: false,
                    };
                }
                case ScheduleActionTypes.OPEN_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal: true,
                        error:''
                    };
                }
                case ScheduleActionTypes.CLOSE_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal:false,
                    };
                }
                default:
                    return { ...state };
            }

        case ScheduleActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
             
                case ScheduleActionTypes.UPDATE_SCHEDULE: {
                    return {
                        ...state,
                        schedules: action.payload.data,
                        loading: false,
                    };
                }
                case ScheduleActionTypes.GET_SCHEDULES: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
              
                default:
                    return { ...state };
            }
          
        default:
            return { ...state };
    }
};

export default User;
