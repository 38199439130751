import { FoliosActionTypes } from "./constants";

export interface FoliosActionsType {
  type:
    | FoliosActionTypes.API_RESPONSE_SUCCESS
    | FoliosActionTypes.API_RESPONSE_ERROR
    | FoliosActionTypes.CREATE_FOLIO
    | FoliosActionTypes.GET_FOLIO_BY_ID
    | FoliosActionTypes.GET_FILE
    | FoliosActionTypes.OPEN_FOLIO_MODAL
    | FoliosActionTypes.CLOSE_FOLIO_MODAL
    | FoliosActionTypes.CLOSE_UPLOAD_FOLIO
    | FoliosActionTypes.APPROVE_FOLIO
    | FoliosActionTypes.REJECT_FOLIO;

  payload: {} | string;
}

interface FolioData {
  orderNumber: string;
  brand: string;
  model: string;
  quantity: number;
  characteristic: string;
  price: number;
  total: number;
}

// common success
export const folioApiResponseSuccess = (
  actionType: string,
  data: FolioData | {}
): FoliosActionsType => ({
  type: FoliosActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const folioApiResponseError = (
  actionType: string,
  error: string
): FoliosActionsType => ({
  type: FoliosActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const saveFolioWithFile = (
  orderNumber: string,
  attendance: number
): FoliosActionsType => ({
  type: FoliosActionTypes.CREATE_FOLIO,
  payload: {
    orderNumber,
    attendance,
  },
});

export const approveFolio = (
  id: number
): FoliosActionsType => ({
  type: FoliosActionTypes.APPROVE_FOLIO,
  payload: {
    id,
  },
});

export const rejectFolio = (
  id: number
): FoliosActionsType => ({
  type: FoliosActionTypes.REJECT_FOLIO,
  payload: {
    id,
  },
});

export const closeUploadFolio = (
  id: number
): FoliosActionsType => ({
  type: FoliosActionTypes.CLOSE_UPLOAD_FOLIO,
  payload: {
    id,
  },
});

export const getFolios = (id: number) => {
  return { type: FoliosActionTypes.GET_FOLIO_BY_ID, payload: { id } };
};

export const getFileFolios = (name: string) => {
  return { type: FoliosActionTypes.GET_FILE, payload: { name } };
};

export const openModalFolio = () => {
  return { type: FoliosActionTypes.OPEN_FOLIO_MODAL };
};
export const closeModalFolio = () => {
  return { type: FoliosActionTypes.CLOSE_FOLIO_MODAL };
};
