// constants
import { ProviderAttendanceActionTypes } from "./constants";

export interface ProviderAttendanceActionType {
  type:
    | ProviderAttendanceActionTypes.API_RESPONSE_SUCCESS
    | ProviderAttendanceActionTypes.API_RESPONSE_ERROR
    | ProviderAttendanceActionTypes.UPDATE_ATTENDANCE
    | ProviderAttendanceActionTypes.GET_ATTENDANCE_BY_PROVIDER
    | ProviderAttendanceActionTypes.GET_ALL_ATTENDANCE
    | ProviderAttendanceActionTypes.OPEN_ATTENDANCE_MODAL
    | ProviderAttendanceActionTypes.CLOSE_ATTENDANCE_MODAL
       
  payload: {} | string;
}

interface AttendanceData {
  id:number;
  contactDate: string;
  arrivalDate : string;
  attended : boolean;
  evaluated: boolean;
  patient : PatientData;
}

export interface PatientData {
  id: number;
  name: string;
  sex: string;
  age: number;
  folio: string;
}

export interface AttendanceUpdate {
  patient:  number;
  provider: number;
  attended: boolean;
}
export interface FatherAttendance {
  providers: AttendanceUpdate[];
}

// common success
export const attendanceApiResponseSuccess = (
  actionType: string,
  data: AttendanceData | {}
): ProviderAttendanceActionType => ({
  type: ProviderAttendanceActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const attendanceApiResponseError = (
  actionType: string,
  error: string
): ProviderAttendanceActionType => ({
  type: ProviderAttendanceActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const updateAttendance = (
  data: FatherAttendance
): ProviderAttendanceActionType => ({
  type: ProviderAttendanceActionTypes.UPDATE_ATTENDANCE,
  payload: data,
});


export const getNotAttendance = ()=>{
  return { type: ProviderAttendanceActionTypes.GET_ALL_ATTENDANCE }
}

export const getAttendanceByProvider = (id : number)=>{
  return { type: ProviderAttendanceActionTypes.GET_ATTENDANCE_BY_PROVIDER,payload: {id} }
}

export const getAttendanceByPatient = (id : number)=>{
  return { type: ProviderAttendanceActionTypes.GET_ATTENDANCE_BY_PATIENT,payload: {id} }
}

export const getPatients = ()=>{
  return { type: ProviderAttendanceActionTypes.GET_ALL_PATIENTS}
}

export const openModalAttendance = () =>{
  return { type: ProviderAttendanceActionTypes.OPEN_ATTENDANCE_MODAL}
}
export const closeModalAttendance = () =>{
  return { type: ProviderAttendanceActionTypes.CLOSE_ATTENDANCE_MODAL}
}

export const resetStates = ()=>{
  return { type: ProviderAttendanceActionTypes.RESET_STATE }
}