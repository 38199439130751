import { ImagingActionTypes } from "./constants";


export interface ImagingsActionsType {
  type:
    | ImagingActionTypes.API_RESPONSE_SUCCESS
    | ImagingActionTypes.API_RESPONSE_ERROR
    | ImagingActionTypes.UPLOAD_IMAGING
    | ImagingActionTypes.LIST_REQUIREMENTS
    | ImagingActionTypes.CLOSE_FILE_UPLOAD
    | ImagingActionTypes.OPEN_MODAL
    | ImagingActionTypes.CLOSE_MODAL
       
  payload: {} | string;
}
interface ImagingData {
  id: string;
  study: string;
  file:  File | null;
}
// common success
export const imagingApiResponseSuccess = (
  actionType: string,
  data: ImagingData | {}
): ImagingsActionsType => ({
  type: ImagingActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const imagingApiResponseError = (
  actionType: string,
  error: string
): ImagingsActionsType => ({
  type: ImagingActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const uploadImaging = (
  study: string,
  file : File | null | undefined
): ImagingsActionsType => ({
  type: ImagingActionTypes.UPLOAD_IMAGING,
  payload: {
    study,
    file
  },
});

export const getRequirements = ()=>{
  return { type: ImagingActionTypes.LIST_REQUIREMENTS }
}
export const openModalArea = () =>{
  return { type: ImagingActionTypes.OPEN_MODAL}
}
export const closeModalArea = () =>{
  return { type: ImagingActionTypes.CLOSE_MODAL}
}