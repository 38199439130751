import { APICore } from "./apiCore";


const api = new APICore();
const baseUrl = "/scales";

export const getAllScales = async (idPatient: number) => {
  return await api.get(`${baseUrl}/all/${idPatient}`,'');
}


export const getFileScale = async (name: string) => {
  console.log(name)
    return await api.get(`${baseUrl}/files/${name}`,'');
  }