// apicore
import { APICore } from '../../helpers/api/apiCore';

// constants
import { ProtocolActionTypes } from './constants';

const api = new APICore();

const INIT_STATE = {
    loading: false,
    protocols:[],
};

interface ProtocolData {
    name: string;
    rfc: string;
    businessName: string;
    addressStreet: string;
    addressSuburb: string;
    addressMayoralty : string;
    addressPostalCode : string;
    addressState : string;
}

interface ProtocolActionType {
    type:
        | ProtocolActionTypes.API_RESPONSE_SUCCESS
        | ProtocolActionTypes.API_RESPONSE_ERROR
        | ProtocolActionTypes.SAVE_PROTOCOL;
    payload: {
        actionType?: string;
        data?: ProtocolData | {};
        error?: string;
    };
}

interface State {
    protocol?: ProtocolData | {};
    loading?: boolean;
    value?: boolean;
}

const Protocol = (state: State = INIT_STATE, action: ProtocolActionType): any => {
   
    switch (action.type) {
        case ProtocolActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ProtocolActionTypes.SAVE_PROTOCOL: {
                    return {
                        ...state,
                        protocol: action.payload.data,
                        loading: false,
                    };
                }
                case ProtocolActionTypes.UPDATE_PROTOCOL: {
                    return {
                        ...state,
                        protocol: action.payload.data,
                        loading: false,
                    };
                }
                case ProtocolActionTypes.GET_PROTOCOLS: {
                    return {
                        ...state,
                        protocol: action.payload.data,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case ProtocolActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ProtocolActionTypes.SAVE_PROTOCOL: {
                    return {
                        ...state,
                        error: action.payload.error,
                        userLoggedIn: false,
                        loading: false,
                    };
                }
              
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default Protocol;
