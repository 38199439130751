import { LaboratoryActionTypes } from "./constants";


export interface LaboratoriesActionsType {
  type:
    | LaboratoryActionTypes.API_RESPONSE_SUCCESS
    | LaboratoryActionTypes.API_RESPONSE_ERROR
    | LaboratoryActionTypes.UPLOAD_LABORATORY
    | LaboratoryActionTypes.LIST_REQUIREMENTS
    | LaboratoryActionTypes.CLOSE_FILE_UPLOAD
    | LaboratoryActionTypes.OPEN_MODAL
    | LaboratoryActionTypes.CLOSE_MODAL
       
  payload: {} | string;
}
interface LaboratoryData {
  id: string;
  study: string;
  file:  File | null;
}
// common success
export const laboratoryApiResponseSuccess = (
  actionType: string,
  data: LaboratoryData | {}
): LaboratoriesActionsType => ({
  type: LaboratoryActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const laboratoryApiResponseError = (
  actionType: string,
  error: string
): LaboratoriesActionsType => ({
  type: LaboratoryActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const uploadLaboratory = (
  study: string,
  file : File | null | undefined
): LaboratoriesActionsType => ({
  type: LaboratoryActionTypes.UPLOAD_LABORATORY,
  payload: {
    study,
    file
  },
});

export const getRequirements = ()=>{
  return { type: LaboratoryActionTypes.LIST_REQUIREMENTS }
}
export const openModalArea = () =>{
  return { type: LaboratoryActionTypes.OPEN_MODAL}
}
export const closeModalArea = () =>{
  return { type: LaboratoryActionTypes.CLOSE_MODAL}
}