export enum EquipmentsActionTypes {
    API_RESPONSE_SUCCESS = '@@equipments/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@equipments/API_RESPONSE_ERROR',
    GET_EQUIPMENT_BY_ID = '@@equipments/GET_EQUIPMENT_BY_ID',
    UPDATE_EQUIPMENT = '@@equipments/UPDATE_EQUIPMENT',
    DELETE_EQUIPMENT = '@@equipments/DELETE_EQUIPMENT',
    GET_FILE = '@@equipments/GET_FILE',
    CREATE_EQUIPMENT = '@@equipments/CREATE_EQUIPMENT',
    OPEN_EQUIPMENT_MODAL = '@@equipments/OPEN_EQUIPMENT_MODAL',
    CLOSE_EQUIPMENT_MODAL = '@@equipments/CLOSE_EQUIPMENT_MODAL',
}
