
// constants
import { ProviderAttendanceActionTypes } from './constants';

interface AttendanceState {
    loading: boolean;
    attendances:AttendanceData[];
    patients: PatientData[];
    modal: boolean;
}
const INIT_STATE : AttendanceState = {
    loading: false,
    attendances:[],
    patients:[],
    modal : false,
};

interface AttendanceData {
    id:number;
    contactDate: string;
    arrivalDate : string;
    attended : boolean;
    evaluated: boolean;
    patient : PatientData;
}
interface PatientData {
    id: number;
    name: string;
    sex: string;
    age: number;
    folio: string;
  }

interface ProviderAttendanceActionType {
    type:
        | ProviderAttendanceActionTypes.API_RESPONSE_SUCCESS
        | ProviderAttendanceActionTypes.API_RESPONSE_ERROR
        | ProviderAttendanceActionTypes.GET_ALL_ATTENDANCE
        | ProviderAttendanceActionTypes.GET_ATTENDANCE_BY_PROVIDER
        | ProviderAttendanceActionTypes.OPEN_ATTENDANCE_MODAL
        | ProviderAttendanceActionTypes.CLOSE_ATTENDANCE_MODAL;
    payload: {
        actionType?: string;
        data?: AttendanceData | {};
        error?: string;
    };
}

interface State {
    loading?: boolean;
    value?: boolean;
    modal:boolean;
}

const Attendance = (state: State = INIT_STATE, action: ProviderAttendanceActionType): any => {
   
    switch (action.type) {
        case ProviderAttendanceActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ProviderAttendanceActionTypes.UPDATE_ATTENDANCE: {
                    return {
                        ...state,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case ProviderAttendanceActionTypes.GET_ALL_ATTENDANCE: {
                    return {
                        ...state,
                        attendances: action.payload.data,
                        loading: false,
                    };
                }
                case ProviderAttendanceActionTypes.GET_ATTENDANCE_BY_PROVIDER: {
                    return {
                        ...state,
                        attendances: action.payload.data,
                        loading: false,
                    };
                }
                case ProviderAttendanceActionTypes.GET_ATTENDANCE_BY_PATIENT: {
                    return {
                        ...state,
                        attendances: action.payload.data,
                        loading: false,
                    };
                }
                case ProviderAttendanceActionTypes.GET_ALL_PATIENTS: {
                    return {
                        ...state,
                        patients: action.payload.data,
                        loading: false,
                    };
                }
                case ProviderAttendanceActionTypes.RESET_STATE: {
                    return {
                        ...state,
                        providers:[],
                        patients:[],
                        attendances: [],
                        loading: false,
                    };
                }
                case ProviderAttendanceActionTypes.OPEN_ATTENDANCE_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal:true,
                        error:''
                    };
                }
                case ProviderAttendanceActionTypes.CLOSE_ATTENDANCE_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal:false,
                    };
                }

                default:
                    return { ...state };
            }

        case ProviderAttendanceActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ProviderAttendanceActionTypes.UPDATE_ATTENDANCE: {
                    return {
                        ...state,
                        loading: false,
                        modal: false,
                        error:action.payload.data
                    };
                }
                case ProviderAttendanceActionTypes.GET_ALL_ATTENDANCE: {
                    return {
                        ...state,
                        attendances: [],
                        loading: false,
                        error:action.payload.data
                    };
                }
                case ProviderAttendanceActionTypes.GET_ATTENDANCE_BY_PROVIDER: {
                    return {
                        ...state,
                        attendances: [],
                        loading: false,
                        error:action.payload.data
                    };
                }

                case ProviderAttendanceActionTypes.GET_ATTENDANCE_BY_PATIENT: {
                    return {
                        ...state,
                        attendances: [],
                        loading: false,
                        error:action.payload.data
                    };
                }
                
                
              
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default Attendance;
