export enum AreasActionTypes {
    API_RESPONSE_SUCCESS = '@@areas/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@areas/API_RESPONSE_ERROR',
    SAVE_AREA = '@@areas/SAVE_AREA',
    UPDATE_AREA = '@@areas/UPDATE_AREA',
    GET_AREAS = '@@areas/GET_AREAS',
    DELETE_AREA = '@@areas/DELETE_AREA',
    OPEN_MODAL = '@@areas/OPEN_MODAL',
    CLOSE_MODAL = '@@areas/CLOSE_MODAL',
}
