export enum ProviderAttendanceActionTypes {
    API_RESPONSE_SUCCESS = '@@providers-attendance/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@providers-attendance/API_RESPONSE_ERROR',
    UPDATE_ATTENDANCE = '@@providers-attendance/UPDATE_ATTENDANCE',
    GET_ATTENDANCE_BY_PROVIDER = '@@providers-attendance/GET_ATTENDANCE_BY_PROVIDER',
    GET_ATTENDANCE_BY_PATIENT = '@@providers-attendance/GET_ATTENDANCE_BY_PATIENT',
    GET_ALL_ATTENDANCE = '@@providers-attendance/GET_ALL_ATTENDANCE',
    GET_ALL_PATIENTS = '@@providers-attendance/GET_ALL_PATIENTS',
    OPEN_ATTENDANCE_MODAL = '@@providers-attendance/OPEN_ATTENDANCE_MODAL',
    CLOSE_ATTENDANCE_MODAL = '@@providers-attendance/CLOSE_ATTENDANCE_MODAL',
    RESET_STATE = '@@providers-attendance/RESET_STATE',
}
