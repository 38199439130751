import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// helpers
import {
  getRequirements as getImagingsApi,

} from "../../helpers";

// actions
import { imagingApiResponseSuccess, imagingApiResponseError } from "./actions";

// constants
import { ImagingActionTypes } from "./constants";

/**
 * Get  Imagings
 * @param {*} payload - 
 */
function* getRequirements(): SagaIterator {
  try {
    const response = yield call(getImagingsApi, );
    const data = response.data;
    yield put(imagingApiResponseSuccess(ImagingActionTypes.LIST_REQUIREMENTS, data));
  } catch (error: any) {
    yield put(imagingApiResponseError(ImagingActionTypes.LIST_REQUIREMENTS, error));
  }
}

export function* watchGetImagings() {
  yield takeEvery(ImagingActionTypes.LIST_REQUIREMENTS, getRequirements);
}

function* openModalImaging({
  type,
}:any): SagaIterator{
    yield put(imagingApiResponseSuccess(ImagingActionTypes.OPEN_MODAL,""));
 
}

export function* watchOpenModalImaging() {
  yield takeEvery(ImagingActionTypes.OPEN_MODAL,openModalImaging);
}

function* closeModalImaging({
  type,
}:any): SagaIterator{
    yield put(imagingApiResponseSuccess(ImagingActionTypes.CLOSE_MODAL,""));
 
}

export function* watchCloseModalImaging() {
  yield takeEvery(ImagingActionTypes.CLOSE_MODAL,closeModalImaging);
}


function* imagingSaga() {
  yield all([
    fork(watchCloseModalImaging),
    fork(watchOpenModalImaging),
    fork(watchGetImagings),
  ]);
}

export default imagingSaga;
