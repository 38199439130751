
import { combineReducers } from 'redux';

import Auth from './auth/reducers';
import Layout from './layout/reducers';
import Hospital from './hospitals/reducers'
import Area from './areas/reducers'
import Directory from './directories/reducers';
import Provider from './providers/reducers';
import Protocol from './protocols/reducers';
import User from './users/reducers'
import Schedule  from './schedules/reducers';
import Equipments from './equipments/reducers';
import Visits from './visits/reducers';
import Enterprise from './enterprises/reducers';
import Folio from './folios/reducers';
import RequirementImaging from './imaging/reducers';
import RequirementLaboratory from './laboratory/reducers';
import StudyImaging from './studies/reducers';
import MedicalNote from './notes/reducers';
import Attendance  from './attendance/reducers';
import Files from './files/reducers';

export default combineReducers({
    Auth,
    Layout,
    Hospital,
    Area,
    Directory,
    Provider,
    Protocol,
    User,
    Schedule,
    Equipments,
    Visits,
    Enterprise,
    Folio,
    StudyImaging,
    RequirementImaging,
    RequirementLaboratory,
    MedicalNote,
    Attendance,
    Files
});
