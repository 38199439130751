import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";


// helpers
import {
  saveProtocol as saveProtocolApi,
  getProtocols as getProtocolsApi,
  updateProtocol as updateProtocolApi,
  deleteProtocol as deleteProtocolApi
} from "../../helpers";

// actions
import { protocolApiResponseSuccess, protocolApiResponseError } from "./actions";

// constants
import { ProtocolActionTypes } from "./constants";

interface ProtocolData {
  payload: {
    protocolId: number;
    hospitalId: number;
    description: string;
    protocolConfig: string;
  };
  type: string;
}


/**
 * Create Protocol
 * @param {*} payload - 
 */
function* createProtocol({
  payload: { protocolId, hospitalId, description, protocolConfig },
  type,
}: ProtocolData): SagaIterator {
  try {
    const response = yield call(saveProtocolApi, {  
      protocolId,
      hospitalId,
      description,
      protocolConfig,
    });
    const data = response.data;
    const protocol = data.protocol;
    
    yield put(protocolApiResponseSuccess(ProtocolActionTypes.SAVE_PROTOCOL, protocol));
  } catch (error: any) {
    yield put(protocolApiResponseError(ProtocolActionTypes.SAVE_PROTOCOL, error));
  }
}



export function* watchCreateProtocol() {
  yield takeEvery(ProtocolActionTypes.SAVE_PROTOCOL, createProtocol);
}


/**
 * Update Protocol
 * @param {*} payload - 
 */
function* updateProtocol({
  payload: { protocolId, hospitalId, description, protocolConfig },
  type,
}: ProtocolData): SagaIterator {
  try {
    const response = yield call(updateProtocolApi, {  
      protocolId,
      hospitalId,
      description,
      protocolConfig,
    });
    const data = response.data;
    const protocol = data.protocol;
    
    yield put(protocolApiResponseSuccess(ProtocolActionTypes.UPDATE_PROTOCOL, protocol));
  } catch (error: any) {
    yield put(protocolApiResponseError(ProtocolActionTypes.UPDATE_PROTOCOL, error));
  }
}



export function* watchUpdateProtocol() {
  yield takeEvery(ProtocolActionTypes.UPDATE_PROTOCOL, updateProtocol);
}

/**
 * Get Protocols
 * @param {*} payload - 
 */
function* getProtocols({
  type,
}: ProtocolData): SagaIterator {
  try {
    const response = yield call(getProtocolsApi, );
    const data = response.data;
    
    
    yield put(protocolApiResponseSuccess(ProtocolActionTypes.GET_PROTOCOLS, data));
  } catch (error: any) {
    yield put(protocolApiResponseError(ProtocolActionTypes.GET_PROTOCOLS, error));
  }
}

export function* watchGetProtocols() {
  yield takeEvery(ProtocolActionTypes.GET_PROTOCOLS, getProtocols);
}


function* protocolSaga() {
  yield all([
    fork(watchCreateProtocol),
    fork(watchUpdateProtocol),
    fork(watchGetProtocols),
  ]);
}

export default protocolSaga;
