import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";


// helpers
import {
  saveProvider as saveProviderApi,
  deleteProviders as deleteProviderApi,
  updateProvider as updateProviderApi,
  getProviders as getProvidersApi,
  getProviderByIdUser as getProviderByIdUserApi,
  getProviderByIdEnterprise as getProviderByIdEnterpriseApi
  
} from "../../helpers";

// actions
import { providerApiResponseSuccess, providerApiResponseError } from "./actions";

// constants
import { ProviderActionTypes } from "./constants";

export interface UserData {
  idUser: number;
  email: string;
  password: string;
  name: string;
  surname: string;
  lastname: string;
  optionalEmail: string;
  localPhone: string;
  localExtension: string;
  directPhone: string;
  directExtension: string;
  cellPhone: string;
  optionalCellPhone : string;
  avatarLink: string;
  role: number;
  hospital: number;
  area: number;
}
interface ProviderData {
  payload: {
    id:number;
    enterprise: number;
    degree : string;
    position : string;
    professionalLicense : boolean;
    officePhone : string;
    officeExtension : string;
    companyLink : string;
    idUser: number;
    email: string;
    password: string;
    name: string;
    surname: string;
    lastname: string;
    optionalEmail: string;
    localPhone: string;
    localExtension: string;
    directPhone: string;
    directExtension: string;
    cellPhone: string;
    optionalCellPhone : string;
    avatarLink: string;
    role: number;
    hospital: number;
    area: number;
  };
  type: string;
}

interface ProviderParamData {
  payload: {
    id:number;
  };
  type: string;
}

/**
 * Create Provider
 * @param {*} payload - 
 */
function* createProvider({
  payload: {   
    enterprise,
    degree ,
    position,
    professionalLicense,
    officePhone,
    officeExtension,
    companyLink,
    email,
    password,
    name,
    surname,
    lastname,
    optionalEmail,
    localPhone,
    localExtension,
    directPhone,
    directExtension,
    cellPhone,
    optionalCellPhone,
    avatarLink,
    role,
    hospital,
    area,
 },
  type,
}: ProviderData): SagaIterator {
  try {
    const response = yield call(saveProviderApi, {  
      enterprise,
      degree ,
      position ,
      professionalLicense,
      officePhone,
      officeExtension,
      companyLink,
      user : {
        email,
        password,
        name,
        surname,
        lastname,
        optionalEmail,
        localPhone,
        localExtension,
        directPhone,
        directExtension,
        cellPhone,
        optionalCellPhone,
        avatarLink,
        role,
        hospital,
        area
      }
    });
    const data = response.data;
    const provider = data.provider;
    
    yield put(providerApiResponseSuccess(ProviderActionTypes.SAVE_PROVIDER, provider));
    yield call(getProviders);
  } catch (error: any) {
    yield put(providerApiResponseError(ProviderActionTypes.SAVE_PROVIDER, error));
  }
}



export function* watchCreateProvider() {
  yield takeEvery(ProviderActionTypes.SAVE_PROVIDER, createProvider);
}

/**
 * Update Provider
 * @param {*} payload - 
 */
function* updateProvider({
  payload: {
    id,        
    enterprise,
    degree ,
    position ,
    professionalLicense,
    officePhone,
    officeExtension,
    companyLink,
      idUser,
      email,
      password,
      name,
      surname,
      lastname,
      optionalEmail,
      localPhone,
      localExtension,
      directPhone,
      directExtension,
      cellPhone,
      optionalCellPhone,
      avatarLink,
      role,
      hospital,
      area
 },
  type,
}: ProviderData): SagaIterator {
  try {
    const response = yield call(updateProviderApi, { 
      id, 
      enterprise,
      degree ,
      position ,
      professionalLicense,
      officePhone,
      officeExtension,
      companyLink,
      user : {
        id: idUser,
        email,
        password,
        name,
        surname,
        lastname,
        optionalEmail,
        localPhone,
        localExtension,
        directPhone,
        directExtension,
        cellPhone,
        optionalCellPhone,
        avatarLink,
        role,
        hospital,
        area
      }
    });
    const data = response.data;
    const provider = data.provider;
    
    yield put(providerApiResponseSuccess(ProviderActionTypes.UPDATE_PROVIDER, provider));
    yield call(getProviders);
  } catch (error: any) {
    yield put(providerApiResponseError(ProviderActionTypes.UPDATE_PROVIDER, error));
  }
}



export function* watchUpdateProvider() {
  yield takeEvery(ProviderActionTypes.UPDATE_PROVIDER, updateProvider);
}

/**
 * Get Providers
 * @param {*} payload - 
 */
function* getProviders(): SagaIterator {
  try {
    const response = yield call(getProvidersApi, );
    const data = response.data;
    yield put(providerApiResponseSuccess(ProviderActionTypes.GET_PROVIDERS, data));
  } catch (error: any) {
    yield put(providerApiResponseError(ProviderActionTypes.GET_PROVIDERS, error));
  }
}



export function* watchGetProviders() {
  yield takeEvery(ProviderActionTypes.GET_PROVIDERS, getProviders);
}

/**
 * Get Providers By User
 * @param {*} payload - 
 */
function* getProviderByUserId({payload:{id},type}: ProviderParamData): SagaIterator {
  try {
    const response = yield call(getProviderByIdUserApi,id );
    const data = response.data;
    yield put(providerApiResponseSuccess(ProviderActionTypes.GET_PROVIDER_BY_USER, data));
  } catch (error: any) {
    yield put(providerApiResponseError(ProviderActionTypes.GET_PROVIDER_BY_USER, error));
  }
}



export function* watchGetProviderByUserId() {
  yield takeEvery(ProviderActionTypes.GET_PROVIDER_BY_USER, getProviderByUserId);
}

/**
 * Get Providers By User
 * @param {*} payload - 
 */
function* getProviderByEnterpriseId({payload:{id},type}: ProviderParamData): SagaIterator {
  try {
    const response = yield call(getProviderByIdEnterpriseApi,id );
    const data = response.data;
    yield put(providerApiResponseSuccess(ProviderActionTypes.GET_PROVIDER_BY_ENTERPRISE, data));
  } catch (error: any) {
    yield put(providerApiResponseError(ProviderActionTypes.GET_PROVIDER_BY_ENTERPRISE, error));
  }
}



export function* watchGetProviderByEnterpriseId() {
  yield takeEvery(ProviderActionTypes.GET_PROVIDER_BY_ENTERPRISE, getProviderByEnterpriseId);
}




function* openModalProvider({
  type,
}:any): SagaIterator{
    yield put(providerApiResponseSuccess(ProviderActionTypes.OPEN_MODAL,""));
 
}

export function* watchOpenModalProvider() {
  yield takeEvery(ProviderActionTypes.OPEN_MODAL,openModalProvider);
}

function* closeModalProvider({
  type,
}:any): SagaIterator{
    yield put(providerApiResponseSuccess(ProviderActionTypes.CLOSE_MODAL,""));
 
}

export function* watchCloseModalProvider() {
  yield takeEvery(ProviderActionTypes.CLOSE_MODAL,closeModalProvider);
}



function* providerSaga() {
  yield all([
    fork(watchOpenModalProvider),
    fork(watchCloseModalProvider),
    fork(watchCreateProvider),
    fork(watchUpdateProvider),
    fork(watchGetProviders),
    fork(watchGetProviderByUserId),
    fork(watchGetProviderByEnterpriseId)
  ]);
}

export default providerSaga;
