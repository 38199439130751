
// constants
import { FilesActionTypes } from './constants';

interface FilesState {
    loading: boolean;
    path : string;
    files: FilesData[];
    modal: boolean;
}

const INIT_STATE : FilesState = {
    path : "",
    loading: false,
    files:[],
    modal : false,
};

interface FilesData {
    id: number;
    protocol : string;
    patient: string;
}

interface FilesActionsType {
    type:
    | FilesActionTypes.API_RESPONSE_SUCCESS
    | FilesActionTypes.API_RESPONSE_ERROR
    | FilesActionTypes.GET_FILES
    | FilesActionTypes.GET_FILE_BY_ID
    | FilesActionTypes.DELETE_FILE
    payload: {
        actionType?: string;
        data?: FilesData | {};
        error?: string;
    };
}

interface State {
    files?: FilesData | {};
    loading?: boolean;
    value?: boolean;
    modal:boolean;
}

const Files = (state: State = INIT_STATE, action: FilesActionsType): any => {
   
    switch (action.type) {
        case FilesActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case FilesActionTypes.GET_FILES: {
                    return {
                        ...state, 
                        files : action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case FilesActionTypes.GET_FILE_BY_NAME: {
                    return {
                        ...state, 
                        file : action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
       
                default:
                    return { ...state };
            }

        case FilesActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case FilesActionTypes.API_RESPONSE_ERROR: {
                    return {
                        ...state, 
                        loading: false,
                        modal: false,
                        error: action.payload.error
                    };
                }
            
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default Files;
