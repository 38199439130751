
// constants
import { ProviderActionTypes } from './constants';

interface ProviderState {
    loading: boolean;
    providers:ProviderData[];
    modal: boolean;
    provider: {};
}
const INIT_STATE : ProviderState = {
    loading: false,
    providers:[],
    provider: {},
    modal : false,
};

interface ProviderData {
    email: string;
    enterprise: string;
    name: string;
    surname: string;
    lastname: string;
    degree : string;
    position : string;
    professionalLicense : number;
    cellPhone : string;
    officePhone : string;
    officeExtension : string;
    directPhone : string;
    directExtension : string;
    avatarLink : string;
    hospitalId : number;
    appointmentId : number;
}

interface ProviderActionType {
    type:
        | ProviderActionTypes.API_RESPONSE_SUCCESS
        | ProviderActionTypes.API_RESPONSE_ERROR
        | ProviderActionTypes.SAVE_PROVIDER
        | ProviderActionTypes.OPEN_MODAL
        | ProviderActionTypes.CLOSE_MODAL;
    payload: {
        actionType?: string;
        data?: ProviderData | {};
        error?: string;
    };
}

interface State {
    provider?: ProviderData | {};
    loading?: boolean;
    value?: boolean;
    modal:boolean;
}

const Provider = (state: State = INIT_STATE, action: ProviderActionType): any => {
   
    switch (action.type) {
        case ProviderActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ProviderActionTypes.SAVE_PROVIDER: {
                    return {
                        ...state,
                        provider: action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case ProviderActionTypes.UPDATE_PROVIDER: {
                    return {
                        ...state,
                        provider: action.payload.data,
                        modal: false,
                        loading: false,
                        error:''
                    };
                }
                case ProviderActionTypes.GET_PROVIDERS: {
                    return {
                        ...state,
                        providers: action.payload.data,
                        loading: false,
                    };
                }
                case ProviderActionTypes.GET_PROVIDER_BY_USER: {
                    return {
                        ...state,
                        provider: action.payload.data,
                        loading: false,
                    };
                }
                case ProviderActionTypes.GET_PROVIDER_BY_ENTERPRISE: {
                    return {
                        ...state,
                        providers: action.payload.data,
                        loading: false,
                    };
                }
                case ProviderActionTypes.OPEN_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal:true,
                        error:''
                    };
                }
                case ProviderActionTypes.CLOSE_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal:false,
                    };
                }

                default:
                    return { ...state };
            }

        case ProviderActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ProviderActionTypes.SAVE_PROVIDER: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case ProviderActionTypes.UPDATE_PROVIDER: {
                    return {
                        ...state,
                        hospital: action.payload.data,
                        loading: false,
                    };
                }
                case ProviderActionTypes.GET_PROVIDERS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case ProviderActionTypes.GET_PROVIDER_BY_ENTERPRISE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case ProviderActionTypes.GET_PROVIDER_BY_USER: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
              
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default Provider;
