export enum DirectoryActionTypes {
    API_RESPONSE_SUCCESS = '@@directories/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@directories/API_RESPONSE_ERROR',
    SAVE_DIRECTORY = '@@directories/SAVE_DIRECTORY',
    UPDATE_DIRECTORY = '@@directories/UPDATE_DIRECTORY',
    GET_DIRECTORIES = '@@directories/GET_DIRECTORIES',
    DELETE_DIRECTORY = '@@directories/DELETE_DIRECTORY',
    OPEN_MODAL = '@@directories/OPEN_MODAL',
    CLOSE_MODAL = '@@directories/CLOSE_MODAL',
}
