

import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// helpers
import {
  saveUser as saveUserApi,
  getUsersByHospital as getUsersApi,
  getAdminUsers as getAdminUsersApi,
  updateUser as updateUserApi,
  deleteUser as deleteUserApi
} from "../../helpers";

// actions
import { userApiResponseSuccess, userApiResponseError } from "./actions";

// constants
import { UserActionTypes } from "./constants";

interface UserData {
  payload: {
    id?:number;
    email: string;
    password: string;
    name: string;
    surname: string;
    lastname: string;
    optionalEmail : string;
    localPhone : string;
    localExtension : string;
    directPhone: string;
    directExtension: string;
    cellPhone: string;
    optionalCellPhone : string;
    avatarLink: string;
    role: number;
    hospital: number;
    area: number;
    adminPosition: string;
    adminArea: string;
    professionalLicense: string;
    degree: string;
  };
  type: string;
}

/**
 * Create User
 * @param {*} payload - 
 */
function* createUser({
  payload: { 
    email,
    password,
    name,
    surname,
    lastname,
    optionalEmail,
    localPhone,
    localExtension,
    directPhone,
    directExtension,
    cellPhone,
    optionalCellPhone,
    avatarLink,
    role,
    hospital,
    area,
    adminPosition,
    adminArea,
    professionalLicense,
    degree,
        },
  type,
}: UserData): SagaIterator {
  try {
    const response = yield call(saveUserApi, {  
      email,
      password,
      name,
      surname,
      lastname,
      optionalEmail,
      localPhone,
      localExtension,
      directPhone,
      directExtension,
      cellPhone,
      optionalCellPhone,
      avatarLink,
      role,
      hospital,
      area,
      adminPosition,
      adminArea,
      professionalLicense,
      degree
    });

    const user = response.data;
    yield call(getUsers);
    yield put(userApiResponseSuccess(UserActionTypes.SAVE_USER, user));
    
  } catch (error: any) {
    yield put(userApiResponseError(UserActionTypes.SAVE_USER, error));
  }
}

export function* watchCreateUser() {
  yield takeEvery(UserActionTypes.SAVE_USER, createUser);
}

/**
 * Update User
 * @param {*} payload - 
 */
function* updateUser({
  payload: { 
    id,
    email,
    password,
    name,
    surname,
    lastname,
    optionalEmail,
    localPhone,
    localExtension,
    directPhone,
    directExtension,
    cellPhone,
    optionalCellPhone,
    avatarLink,
    role,
    hospital,
    area,
    adminPosition,
    adminArea,
    professionalLicense,
    degree,
   },
  type,
}: UserData): SagaIterator {
  try {
    const response = yield call(updateUserApi, {  
      id,
      email,
      password,
      name,
      surname,
      lastname,
      optionalEmail,
      localPhone,
      localExtension,
      directPhone,
      directExtension,
      cellPhone,
      optionalCellPhone,
      avatarLink,
      role,
      hospital,
      area,
      adminPosition,
      adminArea,
      professionalLicense,
      degree,
    });
    const data = response.data;
   
    
    yield put(userApiResponseSuccess(UserActionTypes.UPDATE_USER, data));
    yield call(getUsers);
  } catch (error: any) {
    yield put(userApiResponseError(UserActionTypes.UPDATE_USER, error));
  }
}

export function* watchUpdateUser() {
  yield takeEvery(UserActionTypes.UPDATE_USER, updateUser);
}

/**
 * Get Users
 * @param {*} payload - 
 */
function* getUsers(): SagaIterator {
  try {
    const response = yield call(getUsersApi,);
    const data = response.data;
    yield put(userApiResponseSuccess(UserActionTypes.GET_USERS, data));
  } catch (error: any) {
    yield put(userApiResponseError(UserActionTypes.GET_USERS, error));
  }
}

export function* watchGetUsers() {
  yield takeEvery(UserActionTypes.GET_USERS, getUsers);
}

/**
 * Get Admin Users
 * @param {*} payload - 
 */
function* getAdminUsers(): SagaIterator {
  try {
    const response = yield call(getAdminUsersApi,);
    const data = response.data;
    yield put(userApiResponseSuccess(UserActionTypes.GET_ADMIN_USER, data));
  } catch (error: any) {
    yield put(userApiResponseError(UserActionTypes.GET_ADMIN_USER, error));
  }
}

export function* watchGetAdminUsers() {
  yield takeEvery(UserActionTypes.GET_ADMIN_USER, getAdminUsers);
}

/**
 * Create Admin User
 * @param {*} payload - 
 */
function* createAdminUser({
  payload: { 
    email,
    password,
    name,
    surname,
    lastname,
    optionalEmail,
    localPhone,
    localExtension,
    directPhone,
    directExtension,
    cellPhone,
    optionalCellPhone,
    avatarLink,
    role,
    hospital,
    area,
    adminPosition,
    adminArea,
    professionalLicense,
    degree
        },
  type,
}: UserData): SagaIterator {
  try {
    const response = yield call(saveUserApi, {  
      email,
      password,
      name,
      surname,
      lastname,
      optionalEmail,
      localPhone,
      localExtension,
      directPhone,
      directExtension,
      cellPhone,
      optionalCellPhone,
      avatarLink,
      role,
      hospital,
      area,
      adminPosition,
      adminArea,
      professionalLicense,
      degree,
    });

    const user = response.data;
    yield call(getAdminUsers);
    yield put(userApiResponseSuccess(UserActionTypes.SAVE_ADMIN_USER, user));
    
  } catch (error: any) {
    yield put(userApiResponseError(UserActionTypes.SAVE_ADMIN_USER, error));
  }
}

export function* watchCreateAdminUser() {
  yield takeEvery(UserActionTypes.SAVE_ADMIN_USER, createAdminUser);
}

/**
 * Update Admin User
 * @param {*} payload - 
 */
function* updateAdminUser({
  payload: { 
    id,
    email,
    password,
    name,
    surname,
    lastname,
    optionalEmail,
    localPhone,
    localExtension,
    directPhone,
    directExtension,
    cellPhone,
    optionalCellPhone,
    avatarLink,
    role,
    hospital,
    area,
    adminPosition,
    adminArea,
    professionalLicense,
    degree,
   },
  type,
}: UserData): SagaIterator {
  try {
    const response = yield call(updateUserApi, {  
      id,
      email,
      password,
      name,
      surname,
      lastname,
      optionalEmail,
      localPhone,
      localExtension,
      directPhone,
      directExtension,
      cellPhone,
      optionalCellPhone,
      avatarLink,
      role,
      hospital,
      area,
      adminPosition,
      adminArea,
      professionalLicense,
      degree,
    });
    const data = response.data;
   
    
    yield put(userApiResponseSuccess(UserActionTypes.UPDATE_ADMIN_USER, data));
    yield call(getAdminUsers);
  } catch (error: any) {
    yield put(userApiResponseError(UserActionTypes.UPDATE_ADMIN_USER, error));
  }
}

export function* watchUpdateAdminUser() {
  yield takeEvery(UserActionTypes.UPDATE_ADMIN_USER, updateAdminUser);
}

function* openModalUser({
  type,
}:any): SagaIterator{
    yield put(userApiResponseSuccess(UserActionTypes.OPEN_MODAL,""));
 
}

export function* watchOpenModalUser() {
  yield takeEvery(UserActionTypes.OPEN_MODAL,openModalUser);
}

function* closeModalUser({
  type,
}:any): SagaIterator{
    yield put(userApiResponseSuccess(UserActionTypes.CLOSE_MODAL,""));
 
}

export function* watchCloseModalUser() {
  yield takeEvery(UserActionTypes.CLOSE_MODAL,closeModalUser);
}


function* userSaga() {
  yield all([
    fork(watchCloseModalUser),
    fork(watchOpenModalUser),
    fork(watchCreateUser),
    fork(watchUpdateUser),
    fork(watchGetUsers),
    fork(watchCreateAdminUser),
    fork(watchUpdateAdminUser),
    fork(watchGetAdminUsers),
  ]);
}

export default userSaga;
