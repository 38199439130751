// constants
import { types } from "util";
import { ProtocolActionTypes } from "./constants";

export interface ProtocolActionType {
  type:
    | ProtocolActionTypes.API_RESPONSE_SUCCESS
    | ProtocolActionTypes.API_RESPONSE_ERROR
    | ProtocolActionTypes.SAVE_PROTOCOL
    | ProtocolActionTypes.UPDATE_PROTOCOL
    | ProtocolActionTypes.GET_PROTOCOLS
       
  payload: {} | string;
}
interface ProtocolData {
  name: string;
  rfc: string;
  businessName: string;
  addressStreet: string;
  addressSuburb: string;
  addressMayoralty : string;
  addressPostalCode : number;
  addressState : string;
}
// common success
export const protocolApiResponseSuccess = (
  actionType: string,
  data: ProtocolData | {}
): ProtocolActionType => ({
  type: ProtocolActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const protocolApiResponseError = (
  actionType: string,
  error: string
): ProtocolActionType => ({
  type: ProtocolActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});
export const saveProtocol = (
  name: string,
  rfc: string,
  businessName: string,
  addressStreet: string,
  addressSuburb: string,
  addressMayoralty : string,
  addressPostalCode : number,
  addressState : string,
): ProtocolActionType => ({
  type: ProtocolActionTypes.SAVE_PROTOCOL,
  payload: {
  name,
  rfc,
  businessName,
  addressStreet,
  addressSuburb,
  addressMayoralty,
  addressPostalCode,
  addressState ,
  },
});

export const getProtocols = ()=>{
  return { type: ProtocolActionTypes.GET_PROTOCOLS }
}

export const updateProtocol = (protocol : ProtocolData)=>{
  return { type: ProtocolActionTypes.UPDATE_PROTOCOL,payload: protocol }
}