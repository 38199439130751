import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// helpers
import {
  getLabRequirements as getLaboratoriesApi,

} from "../../helpers";

// actions
import { laboratoryApiResponseSuccess, laboratoryApiResponseError } from "./actions";

// constants
import { LaboratoryActionTypes } from "./constants";

/**
 * Get  Laboratorys
 * @param {*} payload - 
 */
function* getRequirements(): SagaIterator {
  try {
    const response = yield call(getLaboratoriesApi, );
    const data = response.data;
    yield put(laboratoryApiResponseSuccess(LaboratoryActionTypes.LIST_REQUIREMENTS, data));
  } catch (error: any) {
    yield put(laboratoryApiResponseError(LaboratoryActionTypes.LIST_REQUIREMENTS, error));
  }
}

export function* watchGetLaboratories() {
  yield takeEvery(LaboratoryActionTypes.LIST_REQUIREMENTS, getRequirements);
}

function* openModalLaboratory({
  type,
}:any): SagaIterator{
    yield put(laboratoryApiResponseSuccess(LaboratoryActionTypes.OPEN_MODAL,""));
 
}

export function* watchOpenModalLaboratory() {
  yield takeEvery(LaboratoryActionTypes.OPEN_MODAL,openModalLaboratory);
}

function* closeModalLaboratory({
  type,
}:any): SagaIterator{
    yield put(laboratoryApiResponseSuccess(LaboratoryActionTypes.CLOSE_MODAL,""));
 
}

export function* watchCloseModalLaboratory() {
  yield takeEvery(LaboratoryActionTypes.CLOSE_MODAL,closeModalLaboratory);
}


function* laboratorySaga() {
  yield all([
    fork(watchCloseModalLaboratory),
    fork(watchOpenModalLaboratory),
    fork(watchGetLaboratories),
  ]);
}

export default laboratorySaga;
