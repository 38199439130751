import { StudiesActionTypes } from './constants';

interface StudyState {
    loading: boolean;
    studies: StudyData[];
    studyFiles: ClinicalRequirementFilesData[];
    modal: boolean;
}

const INIT_STATE: StudyState = {
    loading: false,
    studies:[],
    modal : false,
    studyFiles: [],
};

interface StudyData {
    name: string;
    description : string;
    flagMedicalTeam:boolean;
    father : number;
}

export interface ClinicalRequirementFilesData {
    id: number;
    name: string;
  }

interface StudyActionsType {
    type:
        | StudiesActionTypes.API_RESPONSE_SUCCESS
        | StudiesActionTypes.API_RESPONSE_ERROR
        | StudiesActionTypes.CLOSE_FILE_UPLOAD
        | StudiesActionTypes.ADD_STUDY_IMAGE
        | StudiesActionTypes.ADD_STUDY_LAB
        | StudiesActionTypes.CLOSE_MODAL
        | StudiesActionTypes.OPEN_MODAL
        | StudiesActionTypes.LIST_STUDIES
        | StudiesActionTypes.DELETE_STUDY_IMAGE
        | StudiesActionTypes.DELETE_STUDY_LAB
        | StudiesActionTypes.GET_FILE_IMAGE
        | StudiesActionTypes.GET_FILE_LAB
        | StudiesActionTypes.UPDATE_STATUS_STUDY
    payload: {
        actionType?: string;
        data?: StudyData | {};
        error?: string;
    };
}

interface State {
    studies?: StudyData | {};
    loading?: boolean;
    value?: boolean;
}

const StudyImaging = (state: State = INIT_STATE, action: StudyActionsType): any => {
   
    switch (action.type) {
        case StudiesActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case StudiesActionTypes.LIST_STUDIES: {
                    return {
                        ...state,
                        studies: action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }

                case StudiesActionTypes.LIST_FILE_STUDIES: {
                    return {
                        ...state,
                        studyFiles: action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }

                case StudiesActionTypes.ADD_STUDY_IMAGE: {
                    return {
                        ...state,
                        studyFile: action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }

                case StudiesActionTypes.GET_FILE_IMAGE: {
                    return {
                        ...state,
                        studyFile: action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }

                case StudiesActionTypes.GET_FILE_LAB: {
                    return {
                        ...state,
                        studyFile: action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }

                case StudiesActionTypes.ADD_STUDY_LAB: {
                    return {
                        ...state,
                        studyFile: action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }

                case StudiesActionTypes.DELETE_STUDY_IMAGE: {
                    return {
                        ...state,
                        studyFile: action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }

                case StudiesActionTypes.DELETE_STUDY_LAB: {
                    return {
                        ...state,
                        studyFile: action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }

                case StudiesActionTypes.UPDATE_STATUS_STUDY: {
                    return {
                        ...state,
                        studyFile: action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
 
                case StudiesActionTypes.OPEN_MODAL: {
                    return {
                        ...state,
                        modal: true,
                        loading: false,
                    };
                }
                case StudiesActionTypes.CLOSE_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal:false,
                        error:''
                    };
                }
                default:
                    return { ...state };
            }

        case StudiesActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case StudiesActionTypes.LIST_STUDIES: {
                    return {
                        ...state,
                        loading: false,
                        modal: false,
                        error:action.payload.error
                    };
                }

                case StudiesActionTypes.ADD_STUDY_IMAGE: {
                    return {
                        ...state,
                        loading: false,
                        modal: false,
                        error:action.payload.error
                    };
                }

                case StudiesActionTypes.ADD_STUDY_LAB: {
                    return {
                        ...state,
                        loading: false,
                        modal: false,
                        error:action.payload.error
                    };
                }

                case StudiesActionTypes.OPEN_MODAL: {
                    return {
                        ...state,
                        error:'',
                        loading: false,
                    };
                }
                case StudiesActionTypes.CLOSE_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal:true,
                        error:''
                    };
                }

                case StudiesActionTypes.DELETE_STUDY_IMAGE: {
                    return {
                        ...state,
                        studyFile: {},
                        loading: false,
                        modal: false,
                        error:action.payload.data
                    };
                }

                case StudiesActionTypes.DELETE_STUDY_LAB: {
                    return {
                        ...state,
                        studyFile: {},
                        loading: false,
                        modal: false,
                        error:action.payload.data
                    };
                }
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default StudyImaging;
