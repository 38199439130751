import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";


// helpers
import {
  getAttendanceByProvider as getAttendanceByProviderApi,
  getNotAttendance as getNotAttendanceApi,
  updateAttendance as updateAttendanceApi,
  getAllPatients as getAllPatientsApi
} from "../../helpers";

// actions
import { attendanceApiResponseSuccess, attendanceApiResponseError, getAttendanceByPatient } from "./actions";

// constants
import { ProviderAttendanceActionTypes } from "./constants";
interface AttendanceData {
  id:number;
  contactDate: string;
  arrivalDate : string;
  attended : boolean;
  evaluated: boolean;
  patient : PatientData;
}

export interface PatientData {
  id: number;
  name: string;
  sex: string;
  age: number;
  folio: string;
}

interface ProviderParamData {
  payload: {
    id:number;
  };
  type: string;
}

interface AttendanceData {
  payload: FatherAttendance;
  id: number;
  type: string;
}

export interface AttendanceUpdate {
  patient:  number;
  provider: number;
  attended: boolean;
}
export interface FatherAttendance {
  providers: AttendanceUpdate[];
}

/**
 * Update Attendance
 * @param {*} payload - 
 */
function* updateAttendance({
  payload: data,
  id,
  type,
}: AttendanceData): SagaIterator {
  try {
    const response = yield call(updateAttendanceApi, data);
    const resData = response.data;
    yield put(getAttendanceByPatient(id));
    yield put(attendanceApiResponseSuccess(ProviderAttendanceActionTypes.UPDATE_ATTENDANCE, resData));
   
  } catch (error: any) {
    yield put(attendanceApiResponseError(ProviderAttendanceActionTypes.UPDATE_ATTENDANCE, error));
  }
}

export function* watchUpdateAttendance() {
  yield takeEvery(ProviderAttendanceActionTypes.UPDATE_ATTENDANCE, updateAttendance);
}

/**
 * Get Providers
 * @param {*} payload - 
 */
function* getNotAttendance({payload:{id},type}: ProviderParamData): SagaIterator {
  try {
    const response = yield call(getNotAttendanceApi, id);
    const data = response.data;
    yield put(attendanceApiResponseSuccess(ProviderAttendanceActionTypes.GET_ATTENDANCE_BY_PATIENT, data));
  } catch (error: any) {
    yield put(attendanceApiResponseError(ProviderAttendanceActionTypes.GET_ATTENDANCE_BY_PATIENT, error));
  }
}



export function* watchGetAttendance() {
  yield takeEvery(ProviderAttendanceActionTypes.GET_ATTENDANCE_BY_PATIENT, getNotAttendance);
}

/**
 * Get Patients
 * @param {*} payload - 
 */
function* getAllPatients(): SagaIterator {
  try {
    const response = yield call(getAllPatientsApi);
    const data = response.data;
    yield put(attendanceApiResponseSuccess(ProviderAttendanceActionTypes.GET_ALL_PATIENTS, data));
  } catch (error: any) {
    yield put(attendanceApiResponseError(ProviderAttendanceActionTypes.GET_ALL_PATIENTS, error));
  }
}



export function* watchGetPatients() {
  yield takeEvery(ProviderAttendanceActionTypes.GET_ALL_PATIENTS, getAllPatients);
}

/**
 * Get Providers
 * @param {*} payload - 
 */
function* getAttendanceByProvider({payload:{id},type}: ProviderParamData): SagaIterator {
  try {
    const response = yield call(getAttendanceByProviderApi, id);
    const data = response.data;
    yield put(attendanceApiResponseSuccess(ProviderAttendanceActionTypes.GET_ATTENDANCE_BY_PROVIDER, data));
  } catch (error: any) {
    yield put(attendanceApiResponseError(ProviderAttendanceActionTypes.GET_ATTENDANCE_BY_PROVIDER, error));
  }
}



export function* watchGetAttendanceByProvider() {
  yield takeEvery(ProviderAttendanceActionTypes.GET_ATTENDANCE_BY_PROVIDER, getAttendanceByProvider);
}




function* openModalAttendance({
  type,
}:any): SagaIterator{
    yield put(attendanceApiResponseSuccess(ProviderAttendanceActionTypes.OPEN_ATTENDANCE_MODAL,""));
 
}

export function* watchOpenModalAttendance() {
  yield takeEvery(ProviderAttendanceActionTypes.OPEN_ATTENDANCE_MODAL,openModalAttendance);
}

function* closeModalAttendance({
  type,
}:any): SagaIterator{
    yield put(attendanceApiResponseSuccess(ProviderAttendanceActionTypes.CLOSE_ATTENDANCE_MODAL,""));
 
}

export function* watchCloseModalAttendance() {
  yield takeEvery(ProviderAttendanceActionTypes.CLOSE_ATTENDANCE_MODAL,closeModalAttendance);
}

function* resetState(): SagaIterator {
  yield put(attendanceApiResponseSuccess(ProviderAttendanceActionTypes.RESET_STATE, ''));
}
export function* watchResetState() {
  yield takeEvery(ProviderAttendanceActionTypes.RESET_STATE, resetState);
}


function* attendanceSaga() {
  yield all([
    fork(watchOpenModalAttendance),
    fork(watchCloseModalAttendance),
    fork(watchUpdateAttendance),
    fork(watchGetAttendanceByProvider),
    fork(watchGetAttendance),
    fork(watchGetPatients),
    fork(watchResetState),
  ]);
}

export default attendanceSaga;
