import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// helpers
import {
  saveEquipment as saveEquipmentApi,
  getEquipmentsByIdOrder as getEquipmentsByIdOrderApi,
  getFileEquipment as getFileEquipmentApi,
  updateEquipment as updateEquipmentApi,
  deleteEquipment as deleteEquipmentApi

} from "../../helpers";

// actions
import { equipmentApiResponseSuccess, equipmentApiResponseError } from "./actions";

// constants
import { EquipmentsActionTypes } from "./constants";

interface EquipmentData {
  payload: {
    id: number;
    orderNumber: number;
    brand :  string;
    model:  string;
    quantity : number;
    characteristic : string;
    price : number;
    total :number;
  };
  type: string;
}

interface responsePath {
  url: string;
}

/**
 * Create Equipment
 * @param {*} payload - 
 */
function* createEquipment({
  payload : {
    orderNumber,
    brand ,
    model,
    quantity ,
    characteristic ,
    price ,
    total ,
  } ,
  type,
} : EquipmentData): SagaIterator {
  try {
    const response = yield call(saveEquipmentApi,{
      orderNumber,
      brand ,
      model,
      quantity ,
      characteristic ,
      price ,
      total ,
    });
    const data = response.data;
    const id = orderNumber;
    yield call(getEquipments,{ payload: { id }, type });
    yield put(equipmentApiResponseSuccess(EquipmentsActionTypes.CREATE_EQUIPMENT, data));
  } catch (error: any) {
    yield put(equipmentApiResponseError(EquipmentsActionTypes.CREATE_EQUIPMENT, error));
  }
}

export function* watchCreateEquipment() {
  yield takeEvery(EquipmentsActionTypes.CREATE_EQUIPMENT, createEquipment);
}

/**
 * Update Equipment
 * @param {*} payload - 
 */
function* updateEquipment({
  payload : {
    id,
    orderNumber,
    brand ,
    model,
    quantity ,
    characteristic ,
    price ,
    total ,
  } ,
  type,
} : EquipmentData): SagaIterator {
  try {
    const response = yield call(updateEquipmentApi,{
      id,
      orderNumber,
      brand ,
      model,
      quantity ,
      characteristic ,
      price ,
      total ,
    });
    const data = response.data;
   
    yield put(equipmentApiResponseSuccess(EquipmentsActionTypes.UPDATE_EQUIPMENT, data));
    yield call(getEquipments,{ payload: { id:orderNumber }, type });
  } catch (error: any) {
    yield put(equipmentApiResponseError(EquipmentsActionTypes.UPDATE_EQUIPMENT, error));
  }
}

export function* watchUpdateEquipment() {
  yield takeEvery(EquipmentsActionTypes.UPDATE_EQUIPMENT, updateEquipment);
}


/**
 * Get Equipments
 * @param {*} payload - 
 */
function* getEquipments({payload:{id},type}:EquipmentData): SagaIterator {
  try {
    const response = yield call(getEquipmentsByIdOrderApi, id);
    const data = response.data;
    yield put(equipmentApiResponseSuccess(EquipmentsActionTypes.GET_EQUIPMENT_BY_ID, data));
  } catch (error: any) {
    yield put(equipmentApiResponseError(EquipmentsActionTypes.GET_EQUIPMENT_BY_ID, error));
  }
}

export function* watchGetEquipments() {
  yield takeEvery(EquipmentsActionTypes.GET_EQUIPMENT_BY_ID, getEquipments);
}


/**
 * Delete
 * @param {*} payload - 
 */
function* deleteEquipment({payload:{id},type}:EquipmentData): SagaIterator {
  try {
    const response = yield call(deleteEquipmentApi, id);
    const data = response.data;
    yield put(equipmentApiResponseSuccess(EquipmentsActionTypes.DELETE_EQUIPMENT, data));
  } catch (error: any) {
    yield put(equipmentApiResponseError(EquipmentsActionTypes.DELETE_EQUIPMENT, error));
  }
}

export function* watchDeleteEquipment() {
  yield takeEvery(EquipmentsActionTypes.DELETE_EQUIPMENT, deleteEquipment);
}



function* openModalEquipment({
  type,
}:any): SagaIterator{
    yield put(equipmentApiResponseSuccess(EquipmentsActionTypes.OPEN_EQUIPMENT_MODAL,""));
 
}

export function* watchOpenModalEquipment() {
  yield takeEvery(EquipmentsActionTypes.OPEN_EQUIPMENT_MODAL,openModalEquipment);
}

function* closeModalEquipment({
  type,
}:any): SagaIterator{
    yield put(equipmentApiResponseSuccess(EquipmentsActionTypes.CLOSE_EQUIPMENT_MODAL,""));
 
}

export function* watchCloseModalEquipment() {
  yield takeEvery(EquipmentsActionTypes.CLOSE_EQUIPMENT_MODAL,closeModalEquipment);
}


function* equipmentSaga() {
  yield all([
    fork(watchGetEquipments),
    fork(watchCloseModalEquipment),
    fork(watchOpenModalEquipment),
    fork(watchCreateEquipment),
    fork(watchUpdateEquipment),
    fork(watchDeleteEquipment),
  ]);
}

export default equipmentSaga;
