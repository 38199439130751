import { APICore } from "./apiCore";

export interface ResponseNoteFile{
    url: string;
  }
const api = new APICore();
const baseUrl = "/medic-notes";
// Medical Note
export const saveMedicalNote =async(
    params :{
        treatingDoctor: number;
        description: string;
        patient: number;
        treatment: number;
    }
)=>{
    return api.create(`${baseUrl}`, params);
}

export const updateMedicalNote =async(
    params :{
        id?: number;
        treatingDoctor: number;
        description: string;
        patient: number;
        treatment: number;
    }
)=>{
    return api.update(`${baseUrl}`, params);
}

export const getAllMedicalNotes = async()=>{
    return api.get(`${baseUrl}/all`, '')
}

export const getPatientsWithoutNote = async()=>{
    return api.get(`${baseUrl}/patients`, '')
}

export const downloadNote = async(name: string)=>{
    return api.getWithType<ResponseNoteFile>(`${baseUrl}/file/${name}`, '');
}
