import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// helpers
import {
  saveFolio as saveFolioApi,
  getFoliosByIdVisit as getFoliosByIdVisitApi,
  getFileFolio as getFileFolioApi,
  approveFolio as approveFolioApi,
   rejectFolio  as rejectFolioApi,
    closeUpload as closeUploadApi,
} from "../../helpers";

// actions
import { folioApiResponseSuccess, folioApiResponseError ,getFolios as getFoliosAction} from "./actions";

// constants
import { FoliosActionTypes } from "./constants";

interface FolioData {
  payload: {
    id: number;
    orderNumber: string;
    attendance :number;
    name : string;
    file:  File | null;
  };
  type: string;
}

interface responsePath {
  url: string;
}

/**
 * Create Folio
 * @param {*} payload - 
 */
function* createFolio({
  payload : {
    orderNumber,
    attendance,
  } ,
  type,
} : FolioData): SagaIterator {
  try {
    const response = yield call(saveFolioApi,{
      orderNumber,
      attendance,
    });
    const data = response.data;
    yield put(folioApiResponseSuccess(FoliosActionTypes.CREATE_FOLIO, data));
  } catch (error: any) {
    yield put(folioApiResponseError(FoliosActionTypes.CREATE_FOLIO, error));
  }
}

export function* watchCreateFolio() {
  yield takeEvery(FoliosActionTypes.CREATE_FOLIO, createFolio);
}


/**
 * Get Folios
 * @param {*} payload - 
 */
function* getFolios({payload:{id},type}:FolioData): SagaIterator {
  try {
    const response = yield call(getFoliosByIdVisitApi, id);
    const data = response.data;
    yield put(folioApiResponseSuccess(FoliosActionTypes.GET_FOLIO_BY_ID, data));
  } catch (error: any) {
    yield put(folioApiResponseError(FoliosActionTypes.GET_FOLIO_BY_ID, error));
  }
}

export function* watchGetFolios() {
  yield takeEvery(FoliosActionTypes.GET_FOLIO_BY_ID, getFolios);
}

/**
 * Get File
 * @param {*} payload - 
 */
function* getFileFolio({payload:{name},type}:FolioData): SagaIterator {
  try {
    const response = yield call(getFileFolioApi, name);
    let url: responsePath;
    url = response.data;
    var fileLink = document.createElement('a');
    fileLink.href = url.url;
    fileLink.setAttribute('download', name);
    document.body.appendChild(fileLink);
    fileLink.click();
    yield put(folioApiResponseSuccess(FoliosActionTypes.GET_FILE, ""));
  } catch (error: any) {
    yield put(folioApiResponseError(FoliosActionTypes.GET_FILE, error));
  }
}

export function* watchGetFileFolios() {
  yield takeEvery(FoliosActionTypes.GET_FILE, getFileFolio);
}

function* approveFolio({payload:{id},type}:FolioData): SagaIterator {
  try {
    const response = yield call(approveFolioApi, {id});
    yield put(getFoliosAction(id));
    yield put(folioApiResponseSuccess(FoliosActionTypes.APPROVE_FOLIO, ""));
  } catch (error: any) {
    yield put(folioApiResponseError(FoliosActionTypes.APPROVE_FOLIO, error));
  }
}

export function* watchApproveFolio() {
  yield takeEvery(FoliosActionTypes.APPROVE_FOLIO, approveFolio);
}

function* rejectFolio({payload:{id},type}:FolioData): SagaIterator {
  try {
    const response = yield call(rejectFolioApi, {id});
   
    yield put(folioApiResponseSuccess(FoliosActionTypes.REJECT_FOLIO, ""));
    yield put(getFoliosAction(id));
  } catch (error: any) {
    yield put(folioApiResponseError(FoliosActionTypes.REJECT_FOLIO, error));
  }
}

export function* watchRejectFolio() {
  yield takeEvery(FoliosActionTypes.REJECT_FOLIO, rejectFolio);
}

function* closeUploadFolio({payload:{id},type}:FolioData): SagaIterator {
  try {
    const response = yield call(closeUploadApi, {id});
   
    yield put(folioApiResponseSuccess(FoliosActionTypes.CLOSE_UPLOAD_FOLIO, ""));
    yield put(getFoliosAction(id));
  } catch (error: any) {
    yield put(folioApiResponseError(FoliosActionTypes.CLOSE_UPLOAD_FOLIO, error));
  }
}

export function* watchCloseUploadFolio() {
  yield takeEvery(FoliosActionTypes.CLOSE_UPLOAD_FOLIO, closeUploadFolio);
}




function* openModalFolio({
  type,
}:any): SagaIterator{
    yield put(folioApiResponseSuccess(FoliosActionTypes.OPEN_FOLIO_MODAL,""));
 
}

export function* watchOpenModalFolio() {
  yield takeEvery(FoliosActionTypes.OPEN_FOLIO_MODAL,openModalFolio);
}

function* closeModalFolio({
  type,
}:any): SagaIterator{
    yield put(folioApiResponseSuccess(FoliosActionTypes.CLOSE_FOLIO_MODAL,""));
 
}

export function* watchCloseModalFolio() {
  yield takeEvery(FoliosActionTypes.CLOSE_FOLIO_MODAL,closeModalFolio);
}


function* folioSaga() {
  yield all([
    fork(watchGetFolios),
    fork(watchCloseModalFolio),
    fork(watchOpenModalFolio),
    fork(watchCreateFolio),
    fork(watchGetFileFolios),
    fork(watchApproveFolio),
    fork(watchRejectFolio),
    fork(watchCloseUploadFolio)
  ]);
}

export default folioSaga;
