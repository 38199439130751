import { VisitsActionTypes } from './constants';

interface VisitState {
    loading: boolean;
    visits: VisitData[];
    modal: boolean;
}

const INIT_STATE : VisitState = {
    loading: false,
    visits:[],
    modal : false,
};

interface VisitData {
    id: number;
    contactDate: Date;
    arrivalDate :  Date;
    provider : ProviderData;
    patientVisit : PatientVisitData;
}

interface ProviderData {
    id: number;
    company: Date;
    companyLink :  string;
}

interface PatientVisitData {
    id: number;
    patient :  PatientData;
}

interface PatientData {
    id: number;
    name: string;
    lastName: string;
    surname: string;
}

interface VisitActionsType {
    type:
    | VisitsActionTypes.API_RESPONSE_SUCCESS
    | VisitsActionTypes.API_RESPONSE_ERROR
    | VisitsActionTypes.GET_VISITS
    payload: {
        actionType?: string;
        data?: VisitData | {};
        path : string;
        error?: string;
    };
}

interface State {
    visit?: VisitData | {};
    loading?: boolean;
    value?: boolean;
}

const Visits = (state: State = INIT_STATE, action: VisitActionsType): any => {

    switch (action.type) {
        case VisitsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case VisitsActionTypes.GET_VISITS: {
                    return {
                        ...state, 
                        visits : action.payload.data,
                        loading: false,
                        error:''
                    };
                }
                case VisitsActionTypes.RESET_STATE: {
                    return {
                        ...state, 
                        visits : [],
                        loading: false,
                        error:''
                    };
                }
                default:
                    return { ...state };
            }

        case VisitsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case VisitsActionTypes.GET_VISITS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }

              
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default Visits;
