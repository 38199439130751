

import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// helpers
import {
  getAllVisitsProvider as getAllVisitsProviderApi,
} from "../../helpers";
// constants
import { VisitsActionTypes } from "./constants";
import { visitApiResponseSuccess, visitApiResponseError } from "./actions";

/**
 * Get Visits Provider
 * @param {*} payload - 
 */
function* getAllVisits(): SagaIterator {
  try {
    const response = yield call(getAllVisitsProviderApi,);
    const data = response.data;
    yield put(visitApiResponseSuccess(VisitsActionTypes.GET_VISITS, data));
  } catch (error: any) {
    yield put(visitApiResponseError(VisitsActionTypes.GET_VISITS, error));
  }
}

export function* watchGetAllVisits() {
  yield takeEvery(VisitsActionTypes.GET_VISITS, getAllVisits);
}

function* resetState(): SagaIterator {
    yield put(visitApiResponseSuccess(VisitsActionTypes.RESET_STATE, ''));
}

export function* watchResetState() {
  yield takeEvery(VisitsActionTypes.RESET_STATE, resetState);
}


function* visitsSaga() {
  yield all([
    fork(watchGetAllVisits),
    fork(watchResetState),
  ]);
}

export default visitsSaga;
