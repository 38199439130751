
import { APICore } from "./apiCore";

const api = new APICore();
const baseUrl = "/hospitals";
export const saveHospital = async (params: { 
  name: string;
  rfc: string;
  businessName: string;
  addressStreet: string;
  addressSuburb: string;
  addressMayoralty : string;
  addressPostalCode : number;
  addressState : string;
  branch: string;
  file:  File | null;
}) => {
  
  return api.createWithFile(`${baseUrl}`, params);
}

export const updateHospital = async (params: { 
  id?: number;
  name: string;
  rfc: string;
  businessName: string;
  addressStreet: string;
  addressSuburb: string;
  addressMayoralty : string;
  addressPostalCode : number;
  addressState : string;
  branch: string;
  file:  File | null;
}) => {
  return api.updateWithFile(`${baseUrl}`, params);
}



export const getHospitals = async () => {
  return await api.get('/hospitals/all','');
}

export const deleteHospital = async ( id: string) => {
  return await api.delete('/hospitals/'+ id);
}
