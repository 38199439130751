
// constants
import { FoliosActionTypes } from './constants';

interface FolioState {
    loading: boolean;
    path : string;
    notes: FolioData[];
    modal: boolean;
}

const INIT_STATE : FolioState = {
    path : "",
    loading: false,
    notes:[],
    modal : false,
};

interface FolioData {
    id: number;
    orderNumber: string;
    provider :number;
}

interface FolioActionsType {
    type:
    | FoliosActionTypes.API_RESPONSE_SUCCESS
    | FoliosActionTypes.API_RESPONSE_ERROR
    | FoliosActionTypes.CREATE_FOLIO
    | FoliosActionTypes.GET_FOLIO_BY_ID
    | FoliosActionTypes.GET_FILE
    | FoliosActionTypes.OPEN_FOLIO_MODAL
    | FoliosActionTypes.CLOSE_FOLIO_MODAL
    payload: {
        actionType?: string;
        data?: FolioData | {};
        error?: string;
    };
}

interface State {
    folio?: FolioData | {};
    loading?: boolean;
    value?: boolean;
}

const Folios = (state: State = INIT_STATE, action: FolioActionsType): any => {
   
    switch (action.type) {
        case FoliosActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case FoliosActionTypes.CREATE_FOLIO: {
                    return {
                        ...state, 
                        folio : action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case FoliosActionTypes.GET_FILE: {
                    return {
                        ...state, 
                        folio : action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case FoliosActionTypes.GET_FOLIO_BY_ID: {
                    return {
                        ...state, 
                        folios : action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case FoliosActionTypes.APPROVE_FOLIO: {
                    return {
                        ...state, 
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case FoliosActionTypes.REJECT_FOLIO: {
                    return {
                        ...state, 
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case FoliosActionTypes.CLOSE_UPLOAD_FOLIO: {
                    return {
                        ...state, 
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case FoliosActionTypes.OPEN_FOLIO_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal:true,
                        error:''
                    };
                }
                case FoliosActionTypes.CLOSE_FOLIO_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal:false,
                        error:''
                    };
                }
                default:
                    return { ...state };
            }

        case FoliosActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case FoliosActionTypes.CREATE_FOLIO: {
                    return {
                        ...state, 

                        loading: false,
                        modal: false,
                        error: action.payload.error
                    };
                }
                case FoliosActionTypes.GET_FILE: {
                    return {
                        ...state, 
                        folio : action.payload.data,
                        loading: false,
                        modal: false,
                        error: action.payload.error
                    };
                }
                case FoliosActionTypes.GET_FOLIO_BY_ID: {
                    return {
                        ...state, 
                        folios : action.payload.data,
                        loading: false,
                        modal: false,
                        error: action.payload.error
                    };
                }

                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default Folios;
