import { VisitsActionTypes } from "./constants";

export interface VisitsActionsType {
  type:
    | VisitsActionTypes.API_RESPONSE_SUCCESS
    | VisitsActionTypes.API_RESPONSE_ERROR
    | VisitsActionTypes.GET_VISITS
    | VisitsActionTypes.RESET_STATE
       
  payload: {} | string;
}
// common success
export const visitApiResponseSuccess = (
  actionType: string,
  data: [] | {}
): VisitsActionsType => ({
  type: VisitsActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const visitApiResponseError = (
  actionType: string,
  error: string
): VisitsActionsType => ({
  type: VisitsActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getAllVisits = ()=>{
  return { type: VisitsActionTypes.GET_VISITS }
}

export const getAllVisitsByProvider = (id: number)=>{
  return { type: VisitsActionTypes.GET_VISITS,payload :{id} }
}

export const resetStates = ()=>{
  return { type: VisitsActionTypes.RESET_STATE }
}
