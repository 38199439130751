// constants
import { ProviderActionTypes } from "./constants";

export interface ProviderActionType {
  type:
    | ProviderActionTypes.API_RESPONSE_SUCCESS
    | ProviderActionTypes.API_RESPONSE_ERROR
    | ProviderActionTypes.SAVE_PROVIDER
    | ProviderActionTypes.UPDATE_PROVIDER
    | ProviderActionTypes.GET_PROVIDERS
    | ProviderActionTypes.DELETE_PROVIDER
    | ProviderActionTypes.GET_PROVIDER_BY_ENTERPRISE
    | ProviderActionTypes.GET_PROVIDER_BY_USER
       
  payload: {} | string;
}
interface ProviderData {
  id:number;
  enterprise: string;
  degree : string;
  position : string;
  professionalLicense : boolean;
  officePhone : string;
  officeExtension : number;
  companyLink : string;
  user : UserData;

}
export interface UserData {
  id: number;
  email: string;
  password: string;
  name: string;
  surname: string;
  lastname: string;
  optionalEmail: string;
  localPhone: string;
  localExtension: number;
  directPhone: string;
  directExtension: number;
  cellPhone: string;
  optionalCellPhone : string;
  avatarLink: string;
  role: number;
  hospital: number;
  area: number;
}

// common success
export const providerApiResponseSuccess = (
  actionType: string,
  data: ProviderData | {}
): ProviderActionType => ({
  type: ProviderActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const providerApiResponseError = (
  actionType: string,
  error: string
): ProviderActionType => ({
  type: ProviderActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});
export const saveProvider = (
  enterprise: number,
  degree : string,
  position : string,
  professionalLicense : boolean,
  officePhone : string,
  officeExtension : string,
  companyLink : string,
  email: string,
  password: string,
  name: string,
  surname: string,
  lastname: string,
  optionalEmail: string,
  localPhone: string,
  localExtension: string,
  directPhone: string,
  directExtension: string,
  cellPhone: string,
  optionalCellPhone : string,
  avatarLink: string,
  role: number,
  hospital: number,
  area: number,
): ProviderActionType => ({
  type: ProviderActionTypes.SAVE_PROVIDER,
  payload: {
    enterprise,
    degree ,
    position ,
    professionalLicense,
    officePhone,
    officeExtension,
    companyLink,
    email,
    password,
    name,
    surname,
    lastname,
    optionalEmail,
    localPhone,
    localExtension,
    directPhone,
    directExtension,
    cellPhone,
    optionalCellPhone,
    avatarLink,
    role,
    hospital,
    area,
  },
});

export const updateProvider = (
  id: number,
  enterprise: number,
  degree : string,
  position : string,
  professionalLicense : boolean,
  officePhone : string,
  officeExtension : string,
  companyLink : string,
  idUser: number,
  email: string,
  password: string,
  name: string,
  surname: string,
  lastname: string,
  optionalEmail: string,
  localPhone: string,
  localExtension: string,
  directPhone: string,
  directExtension: string,
  cellPhone: string,
  optionalCellPhone : string,
  avatarLink: string,
  role: number,
  hospital: number,
  area: number,
): ProviderActionType => ({
  type: ProviderActionTypes.UPDATE_PROVIDER,
  payload: {
    id,
    enterprise,
    degree ,
    position,
    professionalLicense,
    officePhone,
    officeExtension,
    companyLink,
    idUser,
    email,
    password,
    name,
    surname,
    lastname,
    optionalEmail,
    localPhone,
    localExtension,
    directPhone,
    directExtension,
    cellPhone,
    optionalCellPhone,
    avatarLink,
    role,
    hospital,
    area,
  },
});

export const getProviders = ()=>{
  return { type: ProviderActionTypes.GET_PROVIDERS }
}

export const getProvidersByUser = (id : number)=>{
  return { type: ProviderActionTypes.GET_PROVIDER_BY_USER,payload: {id} }
}
export const getProvidersByEnterprise= (id : number)=>{
  return { type: ProviderActionTypes.GET_PROVIDER_BY_ENTERPRISE,payload: {id} }
}

export const deleteProvider = (id: number)=>{
  return { type: ProviderActionTypes.DELETE_PROVIDER, payload: id }
}

export const openModalProvider = () =>{
  return { type: ProviderActionTypes.OPEN_MODAL}
}
export const closeModalProvider = () =>{
  return { type: ProviderActionTypes.CLOSE_MODAL}
}