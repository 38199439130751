export enum FoliosActionTypes {
    API_RESPONSE_SUCCESS = '@@folios/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@folios/API_RESPONSE_ERROR',
    GET_FOLIO_BY_ID = '@@folios/GET_FOLIO_BY_ID',
    GET_FILE = '@@folios/GET_FILE',
    APPROVE_FOLIO ='@@folios/APPROVE_FOLIO',
    REJECT_FOLIO ='@@folios/REJECT_FOLIO',
    CLOSE_UPLOAD_FOLIO ='@@folios/CLOSE_UPLOAD_FOLIO',
    CREATE_FOLIO = '@@folios/CREATE_FOLIO',
    OPEN_FOLIO_MODAL = '@@folios/OPEN_FOLIO_MODAL',
    CLOSE_FOLIO_MODAL = '@@folios/CLOSE_FOLIO_MODAL',
}
