
import { APICore } from "./apiCore";


const api = new APICore();
const baseUrl = "/orders-number";

export const getFoliosByIdVisit = async (id: number) => {
  return await api.get(`${baseUrl}/attendance/${id}`,'');
}

export const getFileFolio = async(name: string) =>{
  return api.get(`${baseUrl}/files/${name}`, '');
}

export const saveFolio = async(params: { 
    orderNumber: string;
    attendance :number;
}) =>{
  return await api.create(`${baseUrl}`,params);
}

export const updateFolio = async(params: { 
  id:number;
  orderNumber : string;
  attendance : number;
  file:  File | null;
}) =>{
  return await api.update(`${baseUrl}`,params);
}

export const approveFolio = async(params: { 
  id:number;
}) =>{
  const status = 3
  return await api.update(`${baseUrl}/${params.id}/${status}`,'');
}

export const rejectFolio = async(params: { 
  id:number;
}) =>{
  const status = 4
  return await api.update(`${baseUrl}/${params.id}/${status}`,'');
}

export const closeUpload = async(params: { 
  id:number;
}) =>{
  const status = 2
  return await api.update(`${baseUrl}/${params.id}/${status}`,'');
}