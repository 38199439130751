
// constants
import { EnterprisesActionTypes } from './constants';

interface EnterpriseState {
    loading: boolean;
    path : string;
    enterprises: EnterpriseData[];
    modal: boolean;
}

const INIT_STATE : EnterpriseState = {
    path : "",
    loading: false,
    enterprises:[],
    modal : false,
};

interface EnterpriseData {
    id: number;
    name: string;
    file:  File;
}

interface EnterpriseActionsType {
    type:
    | EnterprisesActionTypes.API_RESPONSE_SUCCESS
    | EnterprisesActionTypes.API_RESPONSE_ERROR
    | EnterprisesActionTypes.CREATE_ENTERPRISE
    | EnterprisesActionTypes.GET_ALL_ENTERPRISE
    | EnterprisesActionTypes.GET_FILE
    | EnterprisesActionTypes.DELETE_ENTERPRISE
    | EnterprisesActionTypes.OPEN_ENTERPRISE_MODAL
    | EnterprisesActionTypes.CLOSE_ENTERPRISE_MODAL
    payload: {
        actionType?: string;
        data?: EnterpriseData | {};
        error?: string;
    };
}

interface State {
    enterprise?: EnterpriseData | {};
    loading?: boolean;
    value?: boolean;
}

const Enterprise = (state: State = INIT_STATE, action: EnterpriseActionsType): any => {
   
    switch (action.type) {
        case EnterprisesActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case EnterprisesActionTypes.CREATE_ENTERPRISE: {
                    return {
                        ...state, 
                        enterprise : action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case EnterprisesActionTypes.UPDATE_ENTERPRISE: {
                    return {
                        ...state, 
                        enterprise : action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case EnterprisesActionTypes.DELETE_ENTERPRISE: {
                    return {
                        ...state, 
                        enterprise : action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case EnterprisesActionTypes.GET_FILE: {
                    return {
                        ...state, 
                        enterprise : action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case EnterprisesActionTypes.GET_ALL_ENTERPRISE: {
                    return {
                        ...state, 
                        enterprises : action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case EnterprisesActionTypes.OPEN_ENTERPRISE_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal:true,
                        error:''
                    };
                }
                case EnterprisesActionTypes.CLOSE_ENTERPRISE_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal:false,
                        error:''
                    };
                }
                default:
                    return { ...state };
            }

        case EnterprisesActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case EnterprisesActionTypes.CREATE_ENTERPRISE: {
                    return {
                        ...state, 
                        loading: false,
                        modal: false,
                        error: action.payload.error
                    };
                }
                case EnterprisesActionTypes.UPDATE_ENTERPRISE: {
                    return {
                        ...state, 
                        loading: false,
                        modal: false,
                        error: action.payload.error
                    };
                }
                case EnterprisesActionTypes.GET_FILE: {
                    return {
                        ...state, 
                        loading: false,
                        modal: false,
                        error: action.payload.error
                    };
                }
                case EnterprisesActionTypes.GET_ALL_ENTERPRISE: {
                    return {
                        ...state, 
                        loading: false,
                        modal: false,
                        error: action.payload.error
                    };
                }

                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default Enterprise;
