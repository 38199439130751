import React from 'react';
import { Redirect } from 'react-router-dom';

 import { APICore } from '../helpers/api/apiCore';

const Root = () => {
     const api = new APICore();

    const getRootUrl = () => {
        let url: string ;

        // // check if user logged in or not and return url accordingly
        if (api.isUserAuthenticated() === true ) {
            const loggedInUser = api.getLoggedInUser();
            switch (loggedInUser.role.id) {
                case 1:
                    url = 'admin/hospitales';
                    break;
                case 2:
                    url = 'admin/areas';
                    break;  
                case 3:
                    url = 'admin/tablero';
                    break;  
                case 4:
                    url = 'landing';
                    break;  
                case 5:
                    url = 'admin/casos';
                    break;
                case 6:
                    url = 'admin/laboratorio';
                    break;
                case 7:
                    url = 'admin/imagen';
                    break;
                case 9:
                    url = 'admin/nota-medica';
                    break;
                case 10:
                    url = 'admin/casos';
                        break;    
              
                default:
                    url = 'landing';
                    break;
            }
         } else {
             url = 'landing';
         }
        return url;
    };

    const url = getRootUrl();

    return <Redirect to={`/${url}`} />;
};

export default Root;
