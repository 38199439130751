import { EnterprisesActionTypes } from "./constants";

export interface EnterprisesActionsType {
  type:
    | EnterprisesActionTypes.API_RESPONSE_SUCCESS
    | EnterprisesActionTypes.API_RESPONSE_ERROR
    | EnterprisesActionTypes.CREATE_ENTERPRISE
    | EnterprisesActionTypes.UPDATE_ENTERPRISE
    | EnterprisesActionTypes.GET_ALL_ENTERPRISE
    | EnterprisesActionTypes.GET_FILE
    | EnterprisesActionTypes.DELETE_ENTERPRISE
    | EnterprisesActionTypes.OPEN_ENTERPRISE_MODAL
    | EnterprisesActionTypes.CLOSE_ENTERPRISE_MODAL
       
  payload: {} | string;
}

interface EnterpriseData {
  name: string;
  file:  File | null;
}



// common success
export const enterpriseApiResponseSuccess = (
  actionType: string,
  data: EnterpriseData | {}
): EnterprisesActionsType => ({
  type: EnterprisesActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const enterpriseApiResponseError = (
  actionType: string,
  error: string
): EnterprisesActionsType => ({
  type: EnterprisesActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});



export const saveEnterpriseWithFile = (
  name: string,
  file:  FileList | null | undefined,
): EnterprisesActionsType => ({
    type: EnterprisesActionTypes.CREATE_ENTERPRISE,
    payload: {
      name,
      file : file?.item(0),
    },
  });

export const updateEnterpriseWithFile = (
    id: number,
    name: string,
    file:  FileList | null | undefined,
  ): EnterprisesActionsType => ({
      type: EnterprisesActionTypes.UPDATE_ENTERPRISE,
      payload: {
        id,
        name,
        file : file?.item(0),
      },
    });
export const getEnterprises = ()=>{
    return { type: EnterprisesActionTypes.GET_ALL_ENTERPRISE}
}  

export const deleteEnterprise = (id : number)=>{
  return { type: EnterprisesActionTypes.DELETE_ENTERPRISE,payload: {id}}
}  

export const getFileEnterprises = (name : string)=>{
  return { type: EnterprisesActionTypes.GET_FILE ,payload:{name}}
} 

export const openModalEnterprise = () =>{
  return { type: EnterprisesActionTypes.OPEN_ENTERPRISE_MODAL}
}
export const closeModalEnterprise = () =>{
  return { type: EnterprisesActionTypes.CLOSE_ENTERPRISE_MODAL}
}