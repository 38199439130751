import { StudiesActionTypes } from "./constants";


export interface StudyActionTypes {
  type:
    | StudiesActionTypes.API_RESPONSE_SUCCESS
    | StudiesActionTypes.API_RESPONSE_ERROR
    | StudiesActionTypes.LIST_STUDIES
    | StudiesActionTypes.LIST_FILE_STUDIES
    | StudiesActionTypes.ADD_STUDY_IMAGE
    | StudiesActionTypes.ADD_STUDY_LAB
    | StudiesActionTypes.CLOSE_FILE_UPLOAD
    | StudiesActionTypes.OPEN_MODAL
    | StudiesActionTypes.CLOSE_MODAL
    | StudiesActionTypes.DELETE_STUDY_IMAGE
    | StudiesActionTypes.DELETE_STUDY_LAB
    | StudiesActionTypes.GET_FILE_IMAGE
    | StudiesActionTypes.GET_FILE_LAB
    | StudiesActionTypes.UPDATE_STATUS_STUDY
       
  payload: {} | string;
}
interface StudyData {
  id: number;
  status: number;
  initialDate: string;
  file:  File | null | undefined;
}
// common success
export const studyApiResponseSuccess = (
  actionType: string,
  data: StudyData | {}
): StudyActionTypes => ({
  type: StudiesActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const studyApiResponseError = (
  actionType: string,
  error: string
): StudyActionTypes => ({
  type: StudiesActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});


export const getStudies = (id : number)=>{
  return { type: StudiesActionTypes.LIST_STUDIES, payload:{id} }
}

export const getFilesStudy = (id : number)=>{
  return { type: StudiesActionTypes.LIST_FILE_STUDIES, payload:{id} }
}

export const saveStudyImageFile = (
  study: number,
  file:  FileList | null | undefined,
): StudyActionTypes => ({
    type: StudiesActionTypes.ADD_STUDY_IMAGE,
    payload: {
      study,
      file : file?.item(0),
    },
  });

  export const saveStudyLabFile = (
    study: number,
    file:  FileList | null | undefined,
  ): StudyActionTypes => ({
      type: StudiesActionTypes.ADD_STUDY_LAB,
      payload: {
        study,
        file : file?.item(0),
      },
    }); 

export const openModalStudy = () =>{
  return { type: StudiesActionTypes.OPEN_MODAL}
}
export const closeModalStudy = () =>{
  return { type: StudiesActionTypes.CLOSE_MODAL}
}

export const deleteFileImg = (id : number, name: string,  idRequirement: number)=>{
  return { type: StudiesActionTypes.DELETE_STUDY_IMAGE, payload:{id,name,idRequirement} }
}

export const deleteFileLab = (id : number, name: string, idRequirement: number)=>{
  return { type: StudiesActionTypes.DELETE_STUDY_LAB, payload:{id,name,idRequirement} }
}

export const getFileImg = (name: string) => {
  return { type: StudiesActionTypes.GET_FILE_IMAGE, payload: { name } };
};

export const getFileLab = (name: string) => {
  return { type: StudiesActionTypes.GET_FILE_LAB, payload: { name } };
};

export const updateStatusStudy = (id:number, status: number, idRequirement: number) => {
  return { type: StudiesActionTypes.UPDATE_STATUS_STUDY, payload: { id, status, idRequirement } };
};