
import { APICore } from "./apiCore";


const api = new APICore();
const baseUrl = "/providers-attendance";

export interface UpdateData {
  patient: number;
  providerId: number;
  attended : boolean;
}
export interface AttendanceUpdate {
  patient:  number;
  provider: number;
  attended: boolean;
}
export interface FatherAttendance {
  providers: AttendanceUpdate[];
}

export const getAttendanceByProvider = async (id: number) => {
  return await api.get(`${baseUrl}/visit-provider/attended/${id}`,'');
}

export const getNotAttendance = async (id: number) => {
  return await api.get(`${baseUrl}/attended/${id}`,'');
}

export const updateAttendance = async(params:FatherAttendance) =>{
  return await api.update(`${baseUrl}`,params);
}