export enum UserActionTypes {
    API_RESPONSE_SUCCESS = '@@users/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@users/API_RESPONSE_ERROR',
    SAVE_USER = '@@users/SAVE_USER',
    UPDATE_USER = '@@users/UPDATE_USER',
    SAVE_ADMIN_USER = '@@users/SAVE_ADMIN_USER',
    UPDATE_ADMIN_USER = '@@users/UPDATE_ADMIN_USER',
    GET_USERS = '@@users/GET_USERS',
    GET_ADMIN_USER = '@@users/GET_ADMIN_USER',
    DELETE_USER = '@@users/DELETE_USER',
    OPEN_MODAL = '@@users/OPEN_MODAL',
    CLOSE_MODAL = '@@users/CLOSE_MODAL',
}
