export enum MedicalNotesActionTypes {
    API_RESPONSE_SUCCESS = '@@medicals_notes/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@medicals_notes/API_RESPONSE_ERROR',
    CREATE_NOTE = '@@medicals_notes/CREATE_NOTE',
    SAVE_NOTE = '@@medicals_notes/SAVE_NOTE',
    DOWNLOAD_NOTE = '@@medicals_notes/DOWNLOAD_NOTE',
    UPDATE_NOTE = '@@medicals_notes/UPDATE_NOTE',
    GET_NOTES = '@@medicals_notes/GET_NOTES',
    GET_PATIENTS = '@@medicals_notes/GET_PATIENTS',
    DELETE_NOTE = '@@medicals_notes/DELETE_NOTE',
    OPEN_NOTE_MODAL = '@@medicals_notes/OPEN_NOTE_MODAL',
    CLOSE_NOTE_MODAL = '@@medicals_notes/CLOSE_NOTE_MODAL',
}
