
import { APICore } from "./apiCore";

const api = new APICore();
const baseUrl = "/users";
// user
export const saveUser=async(params: { 
  email: string,
  password: string,
  name: string,
  surname: string,
  lastname: string,
  optionalEmail : string,
  localPhone : string,
  localExtension : string,
  directPhone: string,
  directExtension: string,
  cellPhone: string,
  optionalCellPhone : string,
  avatarLink: string,
  role: number,
  hospital: number,
  area: number,
  adminPosition: string,
  adminArea: string,
  professionalLicense: string;
  degree: string;
}) =>{
  return api.create(`${baseUrl}`, params);
}

export const updateUser=async(params: { 
  id?:number;
  email:string,
  password: string,
  name: string,
  surname: string,
  lastname: string,
  optionalEmail : string,
  localPhone : string,
  localExtension : string,
  directPhone: string,
  directExtension: string,
  cellPhone: string,
  optionalCellPhone : string,
  avatarLink: string,
  role: number,
  hospital: number,
  area: number,
  adminPosition: string,
  adminArea: string,
  professionalLicense: string;
  degree: string;
}) =>{
return api.update(`${baseUrl}`, params);
}

export const getAdminUsers = async () => {
  return await api.get(`${baseUrl}/all`,'');
}

export const getUsersByHospital = async () => {
  return await api.get(`${baseUrl}/hospital`,'');
}

export const deleteUser = async (id: number) => {
  return await api.delete(`${baseUrl}/${id}`);
}
