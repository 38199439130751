

// constants
import { MedicalNotesActionTypes } from './constants';


interface MedicalNoteState {
    loading: boolean;
    medicalNote:MedicalNoteData;
    medicalNotes:MedicalNoteData[];
    patients:PatientData[];
    modal: boolean;
}
const INIT_STATE : MedicalNoteState = {
    loading: false,
    medicalNote :{
        treatingDoctor: 0,
        description: '',
        patient: 0,
        treatment: 0
    },
    patients:[],
    medicalNotes:[],
    modal : false,
};

interface MedicalNoteData {
    treatingDoctor: number;
    description: string;
    patient: number;
    treatment: number;
}

interface PatientData {
    id:number;
    name: string;
    sex: string;
    age: number;
    folio: string;
}

interface MedicalNoteActionType {
    type:
        | MedicalNotesActionTypes.API_RESPONSE_SUCCESS
        | MedicalNotesActionTypes.API_RESPONSE_ERROR
        | MedicalNotesActionTypes.SAVE_NOTE
        | MedicalNotesActionTypes.GET_NOTES
        | MedicalNotesActionTypes.GET_PATIENTS
        | MedicalNotesActionTypes.DOWNLOAD_NOTE
        | MedicalNotesActionTypes.OPEN_NOTE_MODAL
        | MedicalNotesActionTypes.CLOSE_NOTE_MODAL;
    payload: {
        actionType?: string;
        data?: MedicalNoteData | {};
        error?: string;
    };
}

interface State {
    medicalNote : MedicalNoteData | {};
    medicalNotes?: MedicalNoteData | {};
    patients?: PatientData | {};
    loading?: boolean;
    value?: boolean;
    modal:boolean;
}

const MedicalNote = (state: State = INIT_STATE, action: MedicalNoteActionType): any => {
   
    switch (action.type) {
        case MedicalNotesActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case MedicalNotesActionTypes.SAVE_NOTE: {
                    return {
                        ...state,
                        medicalNote: action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case MedicalNotesActionTypes.UPDATE_NOTE: {
                    return {
                        ...state,
                        medicalNote: action.payload.data,
                        modal: false,
                        loading: false,
                        error:''
                    };
                }
                case MedicalNotesActionTypes.GET_NOTES: {
                    return {
                        ...state,
                        medicalNotes: action.payload.data,
                        loading: false,
                    };
                }

                case MedicalNotesActionTypes.GET_PATIENTS: {
                    return {
                        ...state,
                        patients: action.payload.data,
                        loading: false,
                    };
                }

                case MedicalNotesActionTypes.DOWNLOAD_NOTE: {
                    return {
                        ...state,
                        patients: action.payload.data,
                        loading: false,
                    };
                } 
                case MedicalNotesActionTypes.OPEN_NOTE_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal:true,
                        error:''
                    };
                }
                case MedicalNotesActionTypes.CLOSE_NOTE_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal:false,
                    };
                }

                default:
                    return { 
                       
                        ...state 
                    };
            }

        case MedicalNotesActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case MedicalNotesActionTypes.SAVE_NOTE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case MedicalNotesActionTypes.UPDATE_NOTE: {
                    return {
                        ...state,
                        medicalNote: action.payload.data,
                        loading: false,
                    };
                }
                case MedicalNotesActionTypes.GET_NOTES: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }

                case MedicalNotesActionTypes.GET_PATIENTS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
              
                default:
                    return { ...state };
            }
    
        default:
            return { ...state };
    }
};

export default MedicalNote;
