export enum StudiesActionTypes {
    API_RESPONSE_SUCCESS = '@@studies/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@studies/API_RESPONSE_ERROR',
    LIST_STUDIES = '@@studies/LIST_STUDIES',
    ADD_STUDY_IMAGE = '@@studies/ADD_STUDY_IMAGE',
    ADD_STUDY_LAB = '@@studies/ADD_STUDY_LAB',
    LIST_FILE_STUDIES = '@@studies/LIST_FILE_STUDIES',
    CLOSE_FILE_UPLOAD = '@@studies/CLOSE_FILE_UPLOAD',
    OPEN_MODAL = '@@studies/OPEN_MODAL',
    CLOSE_MODAL = '@@studies/CLOSE_MODAL',
    DELETE_STUDY_IMAGE = '@@studies/DELETE_STUDY_IMAGE',
    DELETE_STUDY_LAB = '@@studies/DELETE_STUDY_LAB',
    GET_FILE_IMAGE = '@@studies/GET_FILE_IMAGE',
    GET_FILE_LAB = '@@studies/GET_FILE_LAB',
    UPDATE_STATUS_STUDY = '@@studies/UPDATE_STATUS_STUDY',
}
