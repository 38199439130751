
import { APICore } from "./apiCore";


const api = new APICore();
const baseUrl = "/hospital-directory";

export const saveDirectory = async (params: { 
  email:string;
  name: string;
  surname: string;
  lastname: string;
  position: string;
  optionalEmail: string;
  localPhone: string;
  localExtension: string;
  directPhone: string;
  cellPhone: string;
  avatarLink:string;
  hospital:number;
}) => {
  return api.create(`${baseUrl}`, params);
}

export const updateDirectory = async (params: { 
  id:number;
  email:string;
  name: string;
  surname: string;
  lastname: string;
  position: string;
  optionalEmail: string;
  localPhone: string;
  localExtension: string;
  directPhone: string;
  cellPhone: string;
  avatarLink:string;
  hospital:number;
}) => {
  return api.update(`${baseUrl}`, params);
}

export const getDirectories = async () => {
  const result = await api.get(`${baseUrl}/all`,'');
  console.log(result)
  return result;
}

export const deleteDirectories = async ( id: string) => {
  return await api.delete(`${baseUrl}/${id}`);
}










