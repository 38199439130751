export enum ProviderActionTypes {
    API_RESPONSE_SUCCESS = '@@providers/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@providers/API_RESPONSE_ERROR',
    SAVE_PROVIDER = '@@providers/SAVE_PROVIDER',
    UPDATE_PROVIDER = '@@providers/UPDATE_PROVIDER',
    GET_PROVIDERS = '@@providers/GET_PROVIDERS',
    GET_PROVIDER_BY_USER = '@@providers/GET_PROVIDER_BY_USER',
    GET_PROVIDER_BY_ENTERPRISE = '@@providers/GET_PROVIDER_BY_ENTERPRISE',
    DELETE_PROVIDER = '@@providers/DELETE_PROVIDER',
    OPEN_MODAL = '@@providers/OPEN_MODAL',
    CLOSE_MODAL = '@@providers/CLOSE_MODAL',
}
