import { APICore } from "./apiCore";

const api = new APICore();
const baseUrl = "/schedules";

export const updateSchedule=async(params: { 
        id: number,
        weekNumber: number,
        userId: number,
        startTime: string,
        endTime: string,
        onVacation: boolean,
}) =>{
return api.update(`${baseUrl}`, params);
}

export const getSchedules = async (id : number) => {
  return await api.get(`${baseUrl}/user/${id}`,"");
}
