import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// helpers
import {
  getAllScales as getAllScalesApi,
  getFileScale as getFileScaleApi,
} from "../../helpers";

// actions
import { filesApiResponseSuccess, filesApiResponseError } from "./actions";

// constants
import { FilesActionTypes } from "./constants";

interface FilesData {
  payload: {
    id: number;
    protocol : string;
    patient: string;
    name:string;
  };
  type: string;
}

interface responsePath {
  url: string;
}


/**
 * Get Files Scales
 * @param {*} payload - 
 */
function* getFilesScales({payload:{id},type}:FilesData): SagaIterator {
  try {
    const response = yield call(getAllScalesApi, id);
    const data = response.data;
    yield put(filesApiResponseSuccess(FilesActionTypes.GET_FILES, data));
  } catch (error: any) {
    yield put(filesApiResponseError(FilesActionTypes.GET_FILES, error));
  }
}

export function* watchGetFilesScales() {
  yield takeEvery(FilesActionTypes.GET_FILES, getFilesScales);
}


/**
 * Get File
 * @param {*} payload - 
 */
function* getFileScale({payload:{name},type}:FilesData): SagaIterator {
  try {
    const response = yield call(getFileScaleApi, name);
    let url: responsePath;
    url = response.data;
    var fileLink = document.createElement('a');
    fileLink.href = url.url;
    fileLink.setAttribute('download', name);
    document.body.appendChild(fileLink);
    fileLink.click();
    yield put(filesApiResponseSuccess(FilesActionTypes.GET_FILE_BY_NAME, ""));
  } catch (error: any) {
    yield put(filesApiResponseError(FilesActionTypes.GET_FILE_BY_NAME, error));
  }
}

export function* watchGetFileScale() {
  yield takeEvery(FilesActionTypes.GET_FILE_BY_NAME, getFileScale);
}


function* scaleFilesSaga() {
  yield all([
    fork(watchGetFilesScales),
    fork(watchGetFileScale),
  ]);
}

export default scaleFilesSaga;
