export enum HospitalActionTypes {
    API_RESPONSE_SUCCESS = '@@hospitals/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@hospitals/API_RESPONSE_ERROR',
    SAVE_HOSPITAL = '@@hospitals/SAVE_HOSPITAL',
    UPDATE_HOSPITAL = '@@hospitals/UPDATE_HOSPITAL',
    GET_HOSPITAL = '@@hospitals/GET_HOSPITAL',
    DELETE_HOSPITAL = '@@hospitals/DELETE_HOSPITAL',
    OPEN_MODAL = '@@hospitals/OPEN_MODAL',
    CLOSE_MODAL = '@@hospitals/CLOSE_MODAL',
}
