import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// helpers
import {
  getStudies as getStudiesApi,
  getFilesStudies as getFilesStudiesApi,
  uploadFileImaging as uploadFileImagingApi,
  uploadFileLaboratory as uploadFileLaboratoryApi,
  deleteFileImage as deleteFileImageApi,
  deleteFileLab as deleteFileLabApi,
  getFileLab as getFileLabApi,
  getFileImg as getFileImgApi,
  updateStatusStudy as updateStatusStudyApi
} from "../../helpers";

// actions
import { studyApiResponseSuccess, studyApiResponseError } from "./actions";

// constants
import { StudiesActionTypes } from "./constants";


interface responsePath {
  url: string;
}

interface StudyParamData {
  payload: {
    id:number;
  };
  type: string;
}


interface StudyParamDelete {
  payload: {
    id:number;
    name:string;
    idRequirement: number;
  };
  type: string;
}

interface StudyParamStatus{
  payload: {
    id:number;
    idRequirement:number;
    status:number;
  };
  type: string;
}


interface StudyCreateParamData {
  payload: {
    study:number;
    file:  File | null | undefined,
  };
  type: string;
}

/**
 * Get  Studies
 * @param {*} payload - 
 */
function* getStudies({ payload:{id},type}: StudyParamData): SagaIterator {
  try {
    const response = yield call(getStudiesApi,
     id
    );
    const data = response.data;
    yield put(studyApiResponseSuccess(StudiesActionTypes.LIST_STUDIES, data));
  } catch (error: any) {
    yield put(studyApiResponseError(StudiesActionTypes.LIST_STUDIES, error));
  }
}

export function* watchGetStudies() {
  yield takeEvery(StudiesActionTypes.LIST_STUDIES, getStudies);
}


/**
 * Get Files Studies
 * @param {*} payload - 
 */
function* getFileStudies({ payload:{id},type}: StudyParamData): SagaIterator {
  try {
    const response = yield call(getFilesStudiesApi,id);
    const data = response.data;
    yield put(studyApiResponseSuccess(StudiesActionTypes.LIST_FILE_STUDIES, data));
  } catch (error: any) {
    yield put(studyApiResponseError(StudiesActionTypes.LIST_FILE_STUDIES, error));
  }
}

export function* watchGetFileStudies() {
  yield takeEvery(StudiesActionTypes.LIST_FILE_STUDIES, getFileStudies);
}



/**
 * Save File Study Image
 * @param {*} payload - 
 */
function* saveFileImageStudies({ payload:{study,file},type}: StudyCreateParamData): SagaIterator {
  try {
    const response = yield call(uploadFileImagingApi,{study,file});
    const data = response.data;
    yield put(studyApiResponseSuccess(StudiesActionTypes.ADD_STUDY_IMAGE, data));
  } catch (error: any) {
    yield put(studyApiResponseError(StudiesActionTypes.ADD_STUDY_IMAGE, error));
  }
}

export function* watchSaveFileImage() {
  yield takeEvery(StudiesActionTypes.ADD_STUDY_IMAGE, saveFileImageStudies);
}


/**
 * Save File Study Image
 * @param {*} payload - 
 */
function* saveFileLabStudies({ payload:{study,file},type}: StudyCreateParamData): SagaIterator {
  try {
    const response = yield call(uploadFileLaboratoryApi,{study,file});
    const data = response.data;
    yield put(studyApiResponseSuccess(StudiesActionTypes.ADD_STUDY_LAB, data));
  } catch (error: any) {
    yield put(studyApiResponseError(StudiesActionTypes.ADD_STUDY_LAB, error));
  }
}

export function* watchSaveFileLab() {
  yield takeEvery(StudiesActionTypes.ADD_STUDY_LAB, saveFileLabStudies);
}

function* openModalStudy({
  type,
}:any): SagaIterator{
    yield put(studyApiResponseSuccess(StudiesActionTypes.OPEN_MODAL,""));
 
}

export function* watchOpenModalStudy() {
  yield takeEvery(StudiesActionTypes.OPEN_MODAL,openModalStudy);
}

function* closeModalStudy({
  type,
}:any): SagaIterator{
    yield put(studyApiResponseSuccess(StudiesActionTypes.CLOSE_MODAL,""));
 
}

export function* watchCloseModalStudy() {
  yield takeEvery(StudiesActionTypes.CLOSE_MODAL,closeModalStudy);
}


/**
 * Delete Files Img
 * @param {*} payload - 
 */
function* deleteImgFile({ payload:{id,name,idRequirement},type}: StudyParamDelete): SagaIterator {
  try {
    const response = yield call(deleteFileImageApi,{id,name});
    const data = response.data;
  
    yield put(studyApiResponseSuccess(StudiesActionTypes.DELETE_STUDY_IMAGE, data));
    yield call(getFileStudies,{ payload:{id:idRequirement},type});
  } catch (error: any) {
    yield put(studyApiResponseError(StudiesActionTypes.DELETE_STUDY_IMAGE, error));
  }
}

export function* watchDeleteImgFile() {
  yield takeEvery(StudiesActionTypes.DELETE_STUDY_IMAGE, deleteImgFile);
}

/**
 * Delete Files Lab
 * @param {*} payload - 
 */
function* deleteLabFile({ payload:{id,name,idRequirement},type}: StudyParamDelete): SagaIterator {
  try {
    const response = yield call(deleteFileLabApi,{id,name});
    const data = response.data;

    yield put(studyApiResponseSuccess(StudiesActionTypes.DELETE_STUDY_LAB, data));
    yield call(getFileStudies,{ payload:{id:idRequirement},type});
  } catch (error: any) {
    yield put(studyApiResponseError(StudiesActionTypes.DELETE_STUDY_LAB, error));
  }
}

export function* watchDeleteLabFile() {
  yield takeEvery(StudiesActionTypes.DELETE_STUDY_LAB, deleteLabFile);
}


/**
 * Get File
 * @param {*} payload - 
 */
function* getFileImg({payload:{name},type}:StudyParamDelete): SagaIterator {
  try {
    const response = yield call(getFileImgApi, name);
    let url: responsePath;
    url = response.data;
    var fileLink = document.createElement('a');
    fileLink.href = url.url;
    fileLink.setAttribute('download', name);
    document.body.appendChild(fileLink);
    fileLink.click();
    yield put(studyApiResponseSuccess(StudiesActionTypes.GET_FILE_IMAGE, {}));
  } catch (error: any) {
    yield put(studyApiResponseError(StudiesActionTypes.GET_FILE_IMAGE, error));
  }
}

export function* watchGetFileImg() {
  yield takeEvery(StudiesActionTypes.GET_FILE_IMAGE, getFileImg);
}



/**
 * Get File
 * @param {*} payload - 
 */
function* getFileLab({payload:{name},type}:StudyParamDelete): SagaIterator {
  try {
    const response = yield call(getFileLabApi, name);
    let url: responsePath;
    url = response.data;
    var fileLink = document.createElement('a');
    fileLink.href = url.url;
    fileLink.setAttribute('download', name);
    document.body.appendChild(fileLink);
    fileLink.click();
    yield put(studyApiResponseSuccess(StudiesActionTypes.GET_FILE_LAB, {}));
  } catch (error: any) {
    yield put(studyApiResponseError(StudiesActionTypes.GET_FILE_LAB, error));
  }
}

export function* watchGetFileLab() {
  yield takeEvery(StudiesActionTypes.GET_FILE_LAB, getFileLab);
}


/**
 * Update status
 * @param {*} payload - 
 */
function* updateStatusStudy({payload:{id,status,idRequirement},type}:StudyParamStatus): SagaIterator {
  try {
    const response = yield call(updateStatusStudyApi, {id,status});
    yield put(studyApiResponseSuccess(StudiesActionTypes.UPDATE_STATUS_STUDY, {}));
    yield call(getStudies,{ payload:{id:idRequirement},type});
  } catch (error: any) {
    yield put(studyApiResponseError(StudiesActionTypes.UPDATE_STATUS_STUDY, error));
  }
}

export function* watchUpdateStatusStudy() {
  yield takeEvery(StudiesActionTypes.UPDATE_STATUS_STUDY, updateStatusStudy);
}




function* studySaga() {
  yield all([
    fork(watchSaveFileImage),
    fork(watchSaveFileLab),
    fork(watchGetStudies),
    fork(watchGetFileStudies),
    fork(watchCloseModalStudy),
    fork(watchOpenModalStudy),
    fork(watchDeleteImgFile),
    fork(watchDeleteLabFile),
    fork(watchGetFileImg),
    fork(watchGetFileLab),
    fork(watchUpdateStatusStudy),
  ]);
}

export default studySaga;
