
// constants
import { EquipmentsActionTypes } from './constants';

interface EquipmentState {
    loading: boolean;
    path : string;
    notes: EquipmentData[];
    modal: boolean;
}

const INIT_STATE : EquipmentState = {
    path : "",
    loading: false,
    notes:[],
    modal : false,
};

interface EquipmentData {
    id: number;
    orderNumber: string;
    brand :  string;
    model:  string;
    quantity : number;
    characteristic : string;
    price : number;
    total :number;
    file:  File;
}

interface EquipmentActionsType {
    type:
    | EquipmentsActionTypes.API_RESPONSE_SUCCESS
    | EquipmentsActionTypes.API_RESPONSE_ERROR
    | EquipmentsActionTypes.CREATE_EQUIPMENT
    | EquipmentsActionTypes.UPDATE_EQUIPMENT
    | EquipmentsActionTypes.DELETE_EQUIPMENT
    | EquipmentsActionTypes.GET_EQUIPMENT_BY_ID
    | EquipmentsActionTypes.GET_FILE
    | EquipmentsActionTypes.OPEN_EQUIPMENT_MODAL
    | EquipmentsActionTypes.CLOSE_EQUIPMENT_MODAL
    payload: {
        actionType?: string;
        data?: EquipmentData | {};
        error?: string;
    };
}

interface State {
    equipment?: EquipmentData | {};
    loading?: boolean;
    value?: boolean;
    modal:boolean;
}

const Equipments = (state: State = INIT_STATE, action: EquipmentActionsType): any => {
   
    switch (action.type) {
        case EquipmentsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case EquipmentsActionTypes.CREATE_EQUIPMENT: {
                    return {
                        ...state, 
                        equipment : action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case EquipmentsActionTypes.UPDATE_EQUIPMENT: {
                    return {
                        ...state, 
                        equipment : action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case EquipmentsActionTypes.DELETE_EQUIPMENT: {
                    return {
                        ...state, 
                        equipment : action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case EquipmentsActionTypes.GET_FILE: {
                    return {
                        ...state, 
                        equipment : action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case EquipmentsActionTypes.GET_EQUIPMENT_BY_ID: {
                    return {
                        ...state, 
                        equipments : action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case EquipmentsActionTypes.OPEN_EQUIPMENT_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal:true,
                        error:''
                    };
                }
                case EquipmentsActionTypes.CLOSE_EQUIPMENT_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal:false,
                        error:''
                    };
                }
                default:
                    return { ...state };
            }

        case EquipmentsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case EquipmentsActionTypes.CREATE_EQUIPMENT: {
                    return {
                        ...state, 
                        loading: false,
                        modal: false,
                        error: action.payload.error
                    };
                }
                case EquipmentsActionTypes.UPDATE_EQUIPMENT: {
                    return {
                        ...state, 
                        loading: false,
                        modal: false,
                        error: action.payload.error
                    };
                }
                case EquipmentsActionTypes.GET_FILE: {
                    return {
                        ...state, 
                        equipment : action.payload.data,
                        loading: false,
                        modal: false,
                        error: action.payload.error
                    };
                }
                case EquipmentsActionTypes.GET_EQUIPMENT_BY_ID: {
                    return {
                        ...state, 
                        equipments : action.payload.data,
                        loading: false,
                        modal: false,
                        error: action.payload.error
                    };
                }

                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default Equipments;
