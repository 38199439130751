import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";


// helpers
import {
  saveDirectory as saveDirectoryApi,
  deleteDirectories as deleteDirectoriesApi,
  updateDirectory as updateDirectoryApi,
  getDirectories as getDirectoriesApi

} from "../../helpers";

// actions
import { directoryApiResponseSuccess, directoryApiResponseError } from "./actions";

// constants
import { DirectoryActionTypes } from "./constants";

interface DirectoryData {
  payload: {
    id:number;
    email:string;
    name: string;
    surname: string;
    lastname: string;
    position: string;
    optionalEmail: string;
    localPhone: string;
    localExtension: string;
    directPhone: string;
    cellPhone: string;
    avatarLink:string;
    hospital:number;
  };
  type: string;
}



/**
 * Create Directory
 * @param {*} payload - 
 */
function* createDirectory({
  payload: { email, name, surname, lastname,position, optionalEmail, localPhone, localExtension, directPhone, cellPhone,avatarLink, hospital },
  type,
}: DirectoryData): SagaIterator {
  try {
    const response = yield call(saveDirectoryApi, {  
      email,
      name,
      surname,
      lastname,
      position,
      optionalEmail,
      localPhone,
      localExtension,
      directPhone , 
      cellPhone ,
      avatarLink,
      hospital,
    });
    const data = response.data;
    const directory = data.directory;
    
    yield put(directoryApiResponseSuccess(DirectoryActionTypes.SAVE_DIRECTORY, directory));
    
    yield call(getDirectories);

  } catch (error: any) {
    yield put(directoryApiResponseError(DirectoryActionTypes.SAVE_DIRECTORY, error));
  }
}



export function* watchCreateDirectory() {
  yield takeEvery(DirectoryActionTypes.SAVE_DIRECTORY, createDirectory);
}


/**
 * Update Directory
 * @param {*} payload - 
 */
function* updateDirectory({
  payload: {id,email, name, surname, lastname,position, optionalEmail, localPhone, localExtension, directPhone, cellPhone,avatarLink, hospital },
  type,
}: DirectoryData): SagaIterator {
  try {
    const response = yield call(updateDirectoryApi, {  
      id,
      email,
      name,
      surname,
      lastname,
      position,
      optionalEmail,
      localPhone,
      localExtension,
      directPhone , 
      cellPhone ,
      avatarLink,
      hospital,
    });
    const data = response.data;
    const directory = data.directory;
   
    yield put(directoryApiResponseSuccess(DirectoryActionTypes.UPDATE_DIRECTORY, directory));
    yield call(getDirectories);

  } catch (error: any) {
    yield put(directoryApiResponseError(DirectoryActionTypes.UPDATE_DIRECTORY, error));
  }
}



export function* watchUpdateDirectory() {
  yield takeEvery(DirectoryActionTypes.UPDATE_DIRECTORY, updateDirectory);
}

/**
 * Get Directorys
 * @param {*} payload - 
 */
function* getDirectories(): SagaIterator {
  try {
    const response = yield call(getDirectoriesApi );
    const data = response.data;
    yield put(directoryApiResponseSuccess(DirectoryActionTypes.GET_DIRECTORIES, data));
  } catch (error: any) {
    yield put(directoryApiResponseError(DirectoryActionTypes.GET_DIRECTORIES, error));
  }
}



export function* watchGetDirectories() {
  yield takeEvery(DirectoryActionTypes.GET_DIRECTORIES, getDirectories);
}


function* openModalDirectory({
  type,
}:any): SagaIterator{
    yield put(directoryApiResponseSuccess(DirectoryActionTypes.OPEN_MODAL,""));
 
}

export function* watchOpenModalDirectory() {
  yield takeEvery(DirectoryActionTypes.OPEN_MODAL,openModalDirectory);
}

function* closeModalDirectory({
  type,
}:any): SagaIterator{
    yield put(directoryApiResponseSuccess(DirectoryActionTypes.CLOSE_MODAL,""));
 
}

export function* watchCloseModalDirectory() {
  yield takeEvery(DirectoryActionTypes.CLOSE_MODAL,closeModalDirectory);
}



function* directorySaga() {
  yield all([
    fork(watchOpenModalDirectory),
    fork(watchCloseModalDirectory),
    fork(watchCreateDirectory),
    fork(watchUpdateDirectory),
    fork(watchGetDirectories),
  ]);
}

export default directorySaga;
