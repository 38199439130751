import React from "react";
import { Route, RouteProps } from "react-router-dom";

// components
import PrivateRoute from "./PrivateRoute";
import Root from "./Root";

// lazy load all the views

// auth
const Login = React.lazy(() => import("../pages/auth/Login"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
const UsersScreen = React.lazy(() => import("../pages/users"));
const ScheduleScreen = React.lazy(() => import("../pages/schedule"));
const HospitalsScreen = React.lazy(() => import("../pages/hospitals"));
const AreasScreen = React.lazy(() => import("../pages/areas"));
const DirectoryScreen = React.lazy(() => import("../pages/directories"));
const ProtocolScreen = React.lazy(() => import("../pages/protocols"));
const ProvidersScreen = React.lazy(() => import("../pages/providers"));
const UrgencyScreen = React.lazy(() => import("../pages/protocols/Urgency"));
const AdminUserScreen= React.lazy(() => import("../pages/administrators")); 

const ImagingScreen= React.lazy(() => import("../pages/imaging")); 
const ImagingRequirementScreen= React.lazy(() => import("../pages/imaging-studies")); 

const LaboratoryScreen= React.lazy(() => import("../pages/laboratory")); 
const LabRequirementScreen= React.lazy(() => import("../pages/laboratory-studies")); 
const DigitalSignatureScreen= React.lazy(() => import("../pages/digital-signature")); 
const MedicNoteScreen= React.lazy(() => import("../pages/medic-notes")); 
const FormMedicNoteScreen= React.lazy(() => import("../pages/medic-notes/FormMedicNote")); 

const VisitScreen= React.lazy(() => import("../pages/cases")); 
const FoliosScreen= React.lazy(() => import("../pages/folios")); 
const AttendanceScreen= React.lazy(() => import("../pages/attendance")); 
const EquipmentScreen= React.lazy(() => import("../pages/equipments")); 
const EnterpriseScreen= React.lazy(() => import("../pages/enterprises")); 

const ScalesScreen= React.lazy(() => import("../pages/scales")); 

// landing
const Landing = React.lazy(() => import("../pages/landing/"));


// dashboards
const SalesDashboard = React.lazy(() => import("../pages/dashboard/Sales/"));


export interface RoutesProps {
  path: RouteProps["path"];
  name?: string;
  component?: RouteProps["component"];
  route?: any;
  exact?: RouteProps["exact"];
  icon?: string;
  header?: string;
  roles?: string[];
  children?: RoutesProps[];
}

// root routes
const rootRoute: RoutesProps = {
  path: "/",
  exact: true,
  component: () => <Root />,
  route: Route,
};




const adminRoutes: RoutesProps = {
  path: "/admin",
  name: "Administración",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/admin/administradores",
      name: "Usuarios",
      roles:['super_admin'],
      component: AdminUserScreen,
      route: PrivateRoute,
    },
    {
      path: "/admin/usuarios",
      name: "Usuarios",
      roles:['hospital_admin'],
      component: UsersScreen,
      route: PrivateRoute,
    },
    {
      path: "/admin/horarios",
      name: "Horarios",
      roles:['hospital_admin'],
      component: ScheduleScreen,
      route: PrivateRoute,
    },
    {
      path: "/admin/hospitales",
      name: "Hospitales",
      roles:['super_admin'],
      component: HospitalsScreen,
      route: PrivateRoute,
    },
    {
      path: "/admin/areas",
      name: "Areas",
      roles:['hospital_admin'],
      component: AreasScreen,
      route: PrivateRoute,
    },
    {
      path: "/admin/directorios",
      name: "Directorio",
      roles:['super_admin'],
      component: DirectoryScreen,
      route: PrivateRoute,
    },
    {
      path: "/admin/protocolos",
      name: "Protocolos",
      roles:['super_admin'],
      component: ProtocolScreen,
      route: PrivateRoute,
    },
    {
      path: "/admin/proveedores",
      name: "Proveedores",
      roles:['hospital_admin'],
      component: ProvidersScreen,
      route: PrivateRoute,
    },
    {
      path: "/admin/urgencia",
      name: "Urgencias",
      component: UrgencyScreen,
      route: PrivateRoute,
    },
    {
      path: "/admin/casos",
      name: "visits",
      roles:['hospital_admin','provider', 'hemodynamics_manager','hospital_supervisor'],
      component: VisitScreen,
      route: PrivateRoute,
    },
    {
      path: "/admin/folios",
      name: "folios",
      roles:['hospital_admin','provider', 'hemodynamics_manager','hospital_supervisor'],
      component: FoliosScreen,
      route: PrivateRoute,
    },
    {
      path: "/admin/dispositivos",
      name: "dispositivos",
      roles:['hospital_admin','provider', 'hemodynamics_manager','hospital_supervisor'],
      component: EquipmentScreen,
      route: PrivateRoute,
    },
    {
      path: "/admin/asistencia",
      name: "asistencia",
      roles:['hospital_admin','provider', 'hemodynamics_manager'],
      component: AttendanceScreen,
      route: PrivateRoute,
    },
    {
      path: "/admin/requerimientos/imagen",
      name: "imaging",
      roles:['hospital_admin','imaging','hospital_supervisor'],
      component: ImagingScreen,
      route: PrivateRoute
    },
    {
      path: "/admin/imagen/estudios",
      name: "image",
      roles:['hospital_admin','imaging','hospital_supervisor'],
      component: ImagingRequirementScreen,
      route: PrivateRoute,
    },
    {
      path: "/admin/requerimientos/laboratorio",
      name: "laboratory",
      roles:['hospital_admin','laboratory','hospital_supervisor'],
      component: LaboratoryScreen,
      route: PrivateRoute,
    },
    {
      path: "/admin/laboratorio/estudios",
      name: "laboratory",
      roles:['hospital_admin','laboratory','hospital_supervisor'],
      component: LabRequirementScreen,
      route: PrivateRoute,
    },
    {
      path: "/admin/empresas",
      name: "enterprise",
      roles:['hospital_admin'],
      component: EnterpriseScreen,
      route: PrivateRoute,
    },
    {
      path: "/admin/tablero",
      name: "dashboard",
      roles:['super_admin','hospital_admin','hospital_supervisor'],
      component: SalesDashboard,
      route: PrivateRoute,
    },
    {
      path: "/admin/formulario-nota-medica",
      name: "medic-note",
      roles:['hospital_treating_doctor'],
      component: FormMedicNoteScreen,
      route: PrivateRoute,
    }, 
    {
      path: "/admin/nota-medica",
      name: "medic-note",
      roles:['hospital_treating_doctor'],
      component: MedicNoteScreen,
      route: PrivateRoute,
    },
    {
      path: "/admin/firma-digital",
      name: "firma",
      roles:[ 'hospital_treating_doctor'],
      component: DigitalSignatureScreen,
      route: PrivateRoute,
    },
    {
      path: "/admin/archivos/escalas",
      name: "escalas",
      roles:[ 'hospital_admin','hospital_supervisor'],
      component: ScalesScreen,
      route: PrivateRoute,
    }
    
    
  ],
};


// auth
const authRoutes: RoutesProps[] = [
  {
    path: "/auth/login",
    name: "Login",
    component: Login,
    exact: true,
    route: Route,
  },
  {
    path: "/auth/logout",
    name: "Logout",
    exact: true,
    component: Logout,
    route: Route,
  },
];

// public routes
const otherPublicRoutes = [
  {
    path: "/landing",
    name: "landing",
    component: Landing,
    route: Route,
  }
];

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = [];

  routes = routes || [];
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// // All routes
const authProtectedRoutes = [rootRoute, adminRoutes];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
};
