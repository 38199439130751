
import { APICore } from "./apiCore";

const api = new APICore();
const baseUrl = "/protocols";
// protocol
export const saveProtocol = async (params: {
  protocolId: number;
  hospitalId: number;
  description: string;
  protocolConfig: string;

}) => {
  return api.create(`${baseUrl}`, params);
}

export const updateProtocol = async (params: {
  protocolId: number;
  hospitalId: number;
  description: string;
  protocolConfig: string;

}) => {
  return api.update(`${baseUrl}`, params);
}


export const getProtocols = async () => {
  return await api.get(`${baseUrl}`, '');
}

export const deleteProtocol = async (id: number) => {
  return await api.delete(`${baseUrl}/${id}`);
}

export const getHospitalProtocol = async (hospitalId: number, protocolId: number) => {
  return await api.get(`${baseUrl}/hospital/${hospitalId}/protocol/${protocolId}`, '');
}

export const updateHospitalProtocol = async (hospitalId: number, obj: object) => {
  return api.update(`${baseUrl}/hospital/${hospitalId}`, obj);
}
