import { all } from 'redux-saga/effects';

import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import hospitalSaga from './hospitals/saga';
import areaSaga from './areas/saga';
import directorySaga from './directories/saga';
import providerSaga from './providers/saga';
import protocolSaga from './protocols/saga';
import userSaga from './users/saga'
import scheduleSaga from './schedules/saga'
import equipmentSaga from './equipments/saga';
import visitsSaga from './visits/saga';
import enterpriseSaga from './enterprises/saga';
import folioSaga from './folios/saga';
import studySaga from './studies/saga';
import imagingSaga from './imaging/saga';
import laboratorySaga from './laboratory/saga';
import medicalNoteSaga from './notes/saga';
import attendanceSaga from './attendance/saga'
import scaleFilesSaga from './files/saga';

export default function* rootSaga() {
    yield all([authSaga(),
          layoutSaga(),
          hospitalSaga(),
          areaSaga(),
          directorySaga(),
          providerSaga(),
          protocolSaga(),
          userSaga(),
          scheduleSaga(),
          equipmentSaga(),
          visitsSaga(),
          enterpriseSaga(),
          folioSaga(),
          studySaga(),
          imagingSaga(),
          laboratorySaga(),
          medicalNoteSaga(),
          attendanceSaga(),
          scaleFilesSaga(),
            ]);
}
