
import { APICore } from "./apiCore";


const api = new APICore();
const baseUrl = "/equipments";

export const getEquipmentsByIdOrder = async (id: number) => {
  return await api.get(`${baseUrl}/order-number/${id}`,'');
}

export const getFileEquipment = async(name: string) =>{
  return api.get(`${baseUrl}/files/${name}`, '');
}

export const saveEquipment = async(params: { 
    orderNumber: number;
    brand :  string;
    model:  string;
    quantity : number;
    characteristic : string;
    price : number;
    total :number;
}) =>{
  return await api.create(`${baseUrl}`,params);
}

export const updateEquipment = async(params: { 
  id: number;
  orderNumber: number;
  brand :  string;
  model:  string;
  quantity : number;
  characteristic : string;
  price : number;
  total :number;
}) =>{
return await api.update(`${baseUrl}`,params);
}

export const deleteEquipment = async(id: number) =>{
  return api.delete(`${baseUrl}/${id}`);
}

export const saveEquipmentWithFile = async(params: { 
  orderNumber : number;
  orderNote : number;
  brand : string;
  model: string;
  quantity: number;
  price : number;
  amount : number;
  description : string;
  path: string;
}) =>{
  return await api.update(`${baseUrl}`,params);
}
