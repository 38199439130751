// constants

import { ScheduleActionTypes } from "./constants";

export interface ScheduleActionType {
  type:
    | ScheduleActionTypes.API_RESPONSE_SUCCESS
    | ScheduleActionTypes.API_RESPONSE_ERROR
    | ScheduleActionTypes.UPDATE_SCHEDULE
    | ScheduleActionTypes.GET_SCHEDULES
       
  payload: {} | string;
}
interface ScheduleData {
  id: number,
  weekNumber: number,
  userId: number,
  startTime: string,
  endTime: string,
  onVacation: boolean,
}
// common success
export const scheduleApiResponseSuccess = (
  actionType: string,
  data: ScheduleData | {}
): ScheduleActionType => ({
  type: ScheduleActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const scheduleApiResponseError = (
  actionType: string,
  error: string
): ScheduleActionType => ({
  type: ScheduleActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});


export const getSchedules = (id : number)=>{
  return { type: ScheduleActionTypes.GET_SCHEDULES, payload:{ id }}
}

export const updateSchedule = (
  id: number,
  weekNumber: number,
  userId: number,
  startTime: string,
  endTime: string,
  onVacation: boolean,
): ScheduleActionType => ({
  type: ScheduleActionTypes.UPDATE_SCHEDULE,
  payload: {
    id,
    weekNumber,
    userId,
    startTime,
    endTime,
    onVacation
  },
});


export const openModalSchedule = () =>{
  return { type: ScheduleActionTypes.OPEN_MODAL}
}
export const closeModalSchedule = () =>{
  return { type: ScheduleActionTypes.CLOSE_MODAL}
}