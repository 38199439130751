
import { ProviderList } from "../../pages/providers/types";
import { APICore } from "./apiCore";

const api = new APICore();
const baseUrl = "/providers";
// Provider
export interface UserData {
  id: number;
  email: string;
  password: string;
  name: string;
  surname: string;
  lastname: string;
  optionalEmail: string;
  localPhone: string;
  localExtension: string;
  directPhone: string;
  directExtension: string;
  cellPhone: string;
  optionalCellPhone : string;
  avatarLink: string;
  role: number;
  hospital: number;
  area: number;
}
export const saveProvider = async(params: { 
  enterprise: number;
  degree : string;
  position : string;
  professionalLicense : boolean;
  officePhone : string;
  officeExtension : string;
  companyLink : string;
  user : {
    email: string;
    password: string;
    name: string;
    surname: string;
    lastname: string;
    optionalEmail: string;
    localPhone: string;
    localExtension: string;
    directPhone: string;
    directExtension: string;
    cellPhone: string;
    optionalCellPhone : string;
    avatarLink: string;
    role: number;
    hospital: number;
    area: number;
  }
})=> {
  
  return api.create(`${baseUrl}`, params);
}
export const updateProvider = async(params: { 
  id: number;
  enterprise: number;
  degree : string;
  position : string;
  professionalLicense : boolean;
  officePhone : string;
  officeExtension : string;
  companyLink : string;
  user : {
    id : number;
    email: string;
    password: string;
    name: string;
    surname: string;
    lastname: string;
    optionalEmail: string;
    localPhone: string;
    localExtension: string;
    directPhone: string;
    directExtension: string;
    cellPhone: string;
    optionalCellPhone : string;
    avatarLink: string;
    role: number;
    hospital: number;
    area: number;
  }
})=> {
  
  return api.update(`${baseUrl}`, params);
}
export const getProviderByIdUser = async (idUser : number) => {
  return await api.get(`${baseUrl}/user/${idUser}`,'');
}
export const getProviderByIdEnterprise = async (id : number) => {
  return await api.get(`${baseUrl}/enterprise/${id}`,'');
}

export const getProviderByIdUserType = async (idUser : number) => {
  return await api.getWithType<ProviderList>(`${baseUrl}/user/${idUser}`,'');
}


export const getProviders = async () => {
  return await api.get(`${baseUrl}/all`,'');
}

export const deleteProviders = async (id:number) => {
  return await api.delete(`${baseUrl}/${id}`);
}