
import { APICore } from "./apiCore";


const api = new APICore();
const baseUrl = "/areas";
// hospital
export const saveArea= async(params: { 
  description : string;
  flagMedicalTeam:boolean;
}) =>{
  
  return api.create(`${baseUrl}`, params);
}

export const updateArea = async(params: { 
  id: number;
  description : string;
  flagMedicalTeam:boolean;
}) =>{
  return await api.update(`${baseUrl}`,params);
}

export const getAreas = async () => {
  return await api.get(`${baseUrl}/all`,'');
}

export const deleteArea = async (id: number) => {
  return api.delete(`${baseUrl}/${id}`);
}

