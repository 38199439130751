
import { HospitalActionTypes } from "./constants";

export interface HospitalActionType {
  type:
    | HospitalActionTypes.API_RESPONSE_SUCCESS
    | HospitalActionTypes.API_RESPONSE_ERROR
    | HospitalActionTypes.SAVE_HOSPITAL
    | HospitalActionTypes.UPDATE_HOSPITAL
    | HospitalActionTypes.GET_HOSPITAL
       
  payload: {} | string;
}
interface HospitalData {
  name: string;
  rfc: string;
  businessName: string;
  addressStreet: string;
  addressSuburb: string;
  addressMayoralty : string;
  addressPostalCode : number;
  addressState : string;
  branch: string;
  file:  File | null;
}
// common success
export const hospitalApiResponseSuccess = (
  actionType: string,
  data: HospitalData | {}
): HospitalActionType => ({
  type: HospitalActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const hospitalApiResponseError = (
  actionType: string,
  error: string
): HospitalActionType => ({
  type: HospitalActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});
export const saveHospital = (
  name: string,
  rfc: string,
  businessName: string,
  addressStreet: string,
  addressSuburb: string,
  addressMayoralty : string,
  addressPostalCode : number,
  addressState : string,
  branch: string,
  file:  FileList | null | undefined,
): HospitalActionType => ({
  type: HospitalActionTypes.SAVE_HOSPITAL,
  payload: {
  name,
  rfc,
  businessName,
  addressStreet,
  addressSuburb,
  addressMayoralty,
  addressPostalCode,
  addressState ,
  branch,
  file : file?.item(0),
  },
});

export const updateHospital = (
  id: number,
  name: string,
  rfc: string,
  businessName: string,
  addressStreet: string,
  addressSuburb: string,
  addressMayoralty : string,
  addressPostalCode : number,
  addressState : string,
  branch: string,
  file:  FileList | null | undefined,
): HospitalActionType => ({
  type: HospitalActionTypes.UPDATE_HOSPITAL,
  payload: {
  id,
  name,
  rfc,
  businessName,
  addressStreet,
  addressSuburb,
  addressMayoralty,
  addressPostalCode,
  addressState ,
  branch,
  file : file?.item(0),
  },
});


export const getHospitals = ()=>{
  return { type: HospitalActionTypes.GET_HOSPITAL }
}

export const deleteHospital = (id : number)=>{
  return { type: HospitalActionTypes.DELETE_HOSPITAL,payload: id }
}

export const openModalHospital = () =>{
  return { type: HospitalActionTypes.OPEN_MODAL}
}
export const closeModalHospital = () =>{
  return { type: HospitalActionTypes.CLOSE_MODAL}
}