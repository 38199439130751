

// constants
import { HospitalActionTypes } from './constants';


interface HospitalState {
    loading: boolean;
    hospitals:HospitalData[];
    modal: boolean;
}
const INIT_STATE : HospitalState = {
    loading: false,
    hospitals:[],
    modal : false,
};

interface HospitalData {
    name: string;
    rfc: string;
    businessName: string;
    addressStreet: string;
    addressSuburb: string;
    addressMayoralty : string;
    addressPostalCode : string;
    addressState : string;
    branch: string;
    file:  File | null;
}

interface HospitalActionType {
    type:
        | HospitalActionTypes.API_RESPONSE_SUCCESS
        | HospitalActionTypes.API_RESPONSE_ERROR
        | HospitalActionTypes.SAVE_HOSPITAL
        | HospitalActionTypes.OPEN_MODAL
        | HospitalActionTypes.CLOSE_MODAL;
    payload: {
        actionType?: string;
        data?: HospitalData | {};
        error?: string;
    };
}

interface State {
    hospital?: HospitalData | {};
    loading?: boolean;
    value?: boolean;
    modal:boolean;
}

const Hospital = (state: State = INIT_STATE, action: HospitalActionType): any => {
   
    switch (action.type) {
        case HospitalActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case HospitalActionTypes.SAVE_HOSPITAL: {
                    return {
                        ...state,
                        hospital: action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case HospitalActionTypes.UPDATE_HOSPITAL: {
                    return {
                        ...state,
                        hospital: action.payload.data,
                        modal: false,
                        loading: false,
                        error:''
                    };
                }
                case HospitalActionTypes.GET_HOSPITAL: {
                    return {
                        ...state,
                        hospitals: action.payload.data,
                        loading: false,
                    };
                }
                case HospitalActionTypes.OPEN_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal:true,
                        error:''
                    };
                }
                case HospitalActionTypes.CLOSE_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal:false,
                    };
                }

                default:
                    return { 
                       
                        ...state 
                    };
            }

        case HospitalActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case HospitalActionTypes.SAVE_HOSPITAL: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case HospitalActionTypes.UPDATE_HOSPITAL: {
                    return {
                        ...state,
                        hospital: action.payload.data,
                        loading: false,
                    };
                }
                case HospitalActionTypes.GET_HOSPITAL: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
              
                default:
                    return { ...state };
            }
    
        default:
            return { ...state };
    }
};

export default Hospital;
