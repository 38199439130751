

import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// helpers
import {
  saveHospital as saveHospitalApi,
  getHospitals as getHospitalsApi,
  updateHospital as updateHospitalApi,
  deleteHospital as deleteHospitalApi
} from "../../helpers/";

// actions
import { hospitalApiResponseSuccess, hospitalApiResponseError} from "./actions";

// constants
import { HospitalActionTypes } from "./constants";

interface HospitalData {
  payload: {
    id?:number;
    name: string;
    rfc: string;
    businessName: string;
    addressStreet: string;
    addressSuburb: string;
    addressMayoralty : string;
    addressPostalCode : number;
    addressState : string;
    branch: string;
    file:  File | null;
  };
  type: string;
}



/**
 * Create Hospital
 * @param {*} payload - 
 */
function* createHospital({
  payload: { name, rfc, businessName, addressStreet, addressSuburb, addressMayoralty, addressPostalCode, addressState, branch, file },
  type,
}: HospitalData): SagaIterator {
  try {
    const response = yield call(saveHospitalApi, {  
      name,
      rfc,
      businessName,
      addressStreet,
      addressSuburb,
      addressMayoralty,
      addressPostalCode,
      addressState , 
      branch ,
      file,
    });
    const data = response.data;
    const hospital = data.hospital;
    
    yield put(hospitalApiResponseSuccess(HospitalActionTypes.SAVE_HOSPITAL, hospital));
    yield call(getHospitals);

  } catch (error: any) {
    yield put(hospitalApiResponseError(HospitalActionTypes.SAVE_HOSPITAL, error));
  }
}



export function* watchCreateHospital() {
  yield takeEvery(HospitalActionTypes.SAVE_HOSPITAL, createHospital);
}


/**
 * Update Hospital
 * @param {*} payload - 
 */
function* updateHospital({
  payload: {id, name, rfc, businessName, addressStreet, addressSuburb, addressMayoralty, addressPostalCode, addressState ,branch,  file},
  type,
}: HospitalData): SagaIterator {
  try {
    const response = yield call(updateHospitalApi, {
      id,  
      name,
      rfc,
      businessName,
      addressStreet,
      addressSuburb,
      addressMayoralty,
      addressPostalCode,
      addressState , 
      branch ,
      file
    });
    const hospital = response.data.hospital;
    
    yield put(hospitalApiResponseSuccess(HospitalActionTypes.UPDATE_HOSPITAL, hospital));
    yield call(getHospitals);
    
  } catch (error: any) {
    yield put(hospitalApiResponseError(HospitalActionTypes.UPDATE_HOSPITAL, error));
  }
}



export function* watchUpdateHospital() {
  yield takeEvery(HospitalActionTypes.UPDATE_HOSPITAL, updateHospital);
}

/**
 * Get Hospitals
 * @param {*} payload - 
 */
function* getHospitals(): SagaIterator {
  try {
    const response = yield call(getHospitalsApi, );
    const data = response.data;
    yield put(hospitalApiResponseSuccess(HospitalActionTypes.GET_HOSPITAL, data));
  } catch (error: any) {
    yield put(hospitalApiResponseError(HospitalActionTypes.GET_HOSPITAL, error));
  }
}

export function* watchGetHospitals() {
  yield takeEvery(HospitalActionTypes.GET_HOSPITAL, getHospitals);
}



function* openModalHospital({
  type,
}:any): SagaIterator{
    yield put(hospitalApiResponseSuccess(HospitalActionTypes.OPEN_MODAL,""));
 
}

export function* watchOpenModalHospital() {
  yield takeEvery(HospitalActionTypes.OPEN_MODAL,openModalHospital);
}

function* closeModalHospital({
  type,
}:any): SagaIterator{
    yield put(hospitalApiResponseSuccess(HospitalActionTypes.CLOSE_MODAL,""));
 
}

export function* watchCloseModalHospital() {
  yield takeEvery(HospitalActionTypes.CLOSE_MODAL,closeModalHospital);
}






function* hospitalSaga() {
  yield all([
    fork(watchCloseModalHospital),
    fork(watchOpenModalHospital),
    fork(watchCreateHospital),
    fork(watchGetHospitals),
    fork(watchUpdateHospital),
  ]);
}

export default hospitalSaga;
